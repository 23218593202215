import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = ({ percentage }) => {
  return (
    <CircularProgressbar
      value={percentage}
      text={`${percentage}%`}
      styles={buildStyles({
        textColor: "#231F20",
        pathColor: "#FDCD07",
        trailColor: "#F6F4F5",
      })}
    />
  );
};

export default CircularProgressBar;
