const objectPICInfo = (t, strings) => [
  {
    name: "picBusinessName",
    placeholder: t(strings.textPlaceholderEmployeeName),
    label: t(strings.textEmployeeName),
    type: "text",
    isValidate: true,
  },
  {
    name: "picBusinessTitle",
    placeholder: t(strings.textPlaceholderEmployeeTitle),
    label: t(strings.textEmployeeTitle),
    type: "text",
    isValidate: true,
  },
  {
    name: "picBusinessPhoneNumber",
    placeholder: t(strings.textPlaceholderPhoneNumber),
    label: t(strings.textEmployeePhoneNumber),
    type: "text",
    isValidate: true,
  },
  {
    name: "picBusinessEmail",
    placeholder: t(strings.textPlaceholderEmployeeEmail),
    label: t(strings.textEmployeeEmail),
    type: "text",
    isValidate: true,
  },
  {
    name: "picFinanceName",
    placeholder: t(strings.textPlaceholderEmployeeName),
    label: t(strings.textEmployeeName),
    type: "text",
    isValidate: true,
  },
  {
    name: "picFinanceTitle",
    placeholder: t(strings.textPlaceholderEmployeeTitle),
    label: t(strings.textEmployeeTitle),
    type: "text",
    isValidate: true,
  },
  {
    name: "picFinancePhoneNumber",
    placeholder: t(strings.textPlaceholderPhoneNumber),
    label: t(strings.textEmployeePhoneNumber),
    type: "text",
    isValidate: true,
  },
  {
    name: "picFinanceEmail",
    placeholder: t(strings.textPlaceholderEmployeeEmail),
    label: t(strings.textEmployeeEmail),
    type: "text",
    isValidate: true,
  },
  {
    name: "picTechnicalName",
    placeholder: t(strings.textPlaceholderEmployeeName),
    label: t(strings.textEmployeeName),
    type: "text",
    isValidate: true,
  },
  {
    name: "picTechnicalTitle",
    placeholder: t(strings.textPlaceholderEmployeeTitle),
    label: t(strings.textEmployeeTitle),
    type: "text",
    isValidate: true,
  },
  {
    name: "picTechnicalPhoneNumber",
    placeholder: t(strings.textPlaceholderPhoneNumber),
    label: t(strings.textEmployeePhoneNumber),
    type: "text",
    isValidate: true,
  },
  {
    name: "picTechnicalEmail",
    placeholder: t(strings.textPlaceholderEmployeeEmail),
    label: t(strings.textEmployeeEmail),
    type: "text",
    isValidate: true,
  },
];

export default objectPICInfo;
