const strings = {
  textHavingAccount: "Already have an account?",
  textYellow14: "Sign in",
  textTagline: "Connected payment solutions for your business",
  textBusinessName: "Business Name",
  textFullname: "Full Name",
  textEmail: "Email",
  textPhoneNumber: "Phone Number",
  textBusinessType: "Business Type",
  textPassword: "Password",
  textNewPassword: "New Password",
  textRetypeNewPassword: "Retype New Password",
  textAddress: "Address",
  textCorporate: "Corporate",
  textNonCorporate: "Non Corporate",
  textByCreatingAccount: "By creating this account, I agree to",
  textTermsOfService: "Terms of Service",
  textAnd: "and",
  textPrivacyPolicy: "Privacy Policy",

  textRegister: "Register",
  textOptional: "Optional",
  textRegistSuccess: "Your Account Registration was Successful!",
  textClickLinkTo: "Click the link sent to",
  textToActivate: "to activate your account",
  textAskNoEmailSent: "No email in your inbox or spam folder?",
  textResendIt: "Resend it",
  textServiceNotAvailable: "Sorry, this service is unavailable",
  textSuccessRegist: "Account registration is successful",
  textAccountActivationSuccess: "Account activation is successful!",
  textAccountActivationFailed: "Account activation is failed!",
  textAccountActivationSuspend: "Account has been suspended!",
  textAccountActivationSuccessDescription:
    "You can continue the activity by logging into IFortepay Merchant Onboarding portal using your account.",
  textAccountActivationFailedDescription:
    "We are unable to complete your account verification process at this time. Please repeat your verification process. If this issue persists, please contact IFortepay support team for further assistance.",
  textAccountActivationSuspendDescription:
    "Your account is currently suspended and cannot be activated. Please contact customer support for further assistance.",
  textContactCS: "Contact Customer Support",
  textActivateYourAccount: "Activate your account",
  textActivateAccount: "Activate Account",
  textActivateAccountDescription:
    "Please activate your account by clicking the button below.",
  textForgetPassword: "Forgot Password",
  textLogin: "Log in",
  textResetPassword: "Reset password",
  textResetPasswordDescription:
    "Enter the email address you used for registration. Next, we will send you instructions to reset your password.",
  textReset: "Reset",
  textCheckEmail: "Check your email",
  textResetPasswordSuccess: "You've successfully changed your password!",
  textResetPasswordSuccessDesc:
    "Use the new password to log into your account. Remember, don't share your password with anyone!",
  textForgotPasswordSuccessDesc:
    "We have sent instructions to reset the password to",
  textChangePasswordDescription:
    "The new password must be different from the password used before!",
  textCopyright: "©2023 PT IFORTE PAYMENT INFRASTUCTURE. All rights reserved.",
  textGoToLogin: "Go to the log in page",

  // condition
  textThePasswordMustConsistOf: "The password must consist of",
  textPasswordCondition1: "Minimum 8 characters",
  textPasswordCondition2:
    "Use a combination of uppercase letters (A-Z), lowercase letters (a-z), and numbers.",
  textPasswordCondition3: "Special characters (Example: ? & !@#$).",

  //placeholder
  textPlaceholderBusinessName: "Company XYZ",
  textPlaceholderFullName: "John Doe",
  textPlaceholderEmail: "john@mail.com",
  textPlaceholderEmail2: "Type in your email",
  textPlaceholderPhoneNumber: "+628xxxx or 08xxxx",
  textPlaceholderPassword: "Create your password",
  textPlaceholderPassword2: "Type in your password",
  textPlaceholderPasswordNew: "Create a new password",
  textPlaceholderPasswordRetypeNew: "Retype new password",
  textPlaceholderAddress: "Type in your address",
};

export default strings;
