import Textfields from "../../General/Component/Textfields";

export const renderObjectBank = (
  classes,
  field,
  index,
  formData,
  handleChange
) => {
  const isIDR = formData.currency === "IDR";
  const isUSD = formData.currency === "USD";

  const idrFields = [
    "bankName",
    "bankBranchName",
    "bankAccountName",
    "bankNumber",
  ];
  const usdFields = [
    "bankName",
    "bankSwiftNumber",
    "bankAccountName",
    "bankNumber",
  ];

  if (isIDR && idrFields.includes(field.name)) {
    return (
      <div className={classes.divHalf} key={index}>
        <Textfields
          isHideLabel={true}
          placeholder={field.placeholder}
          label={field.label}
          type={field.type}
          name={field.name}
          value={formData[field.name]}
          onChange={handleChange}
          isValidate={true}
          isOptional={false}
        />
      </div>
    );
  } else if (isUSD && usdFields.includes(field.name)) {
    return (
      <div className={classes.divHalf} key={index}>
        <Textfields
          isHideLabel={true}
          placeholder={field.placeholder}
          label={field.label}
          type={field.type}
          name={field.name}
          value={formData[field.name]}
          onChange={handleChange}
          isValidate={true}
          isOptional={false}
        />
      </div>
    );
  }
};
