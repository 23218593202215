import classes from "../RegistMerchant.module.css";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import { useTranslation } from "react-i18next";
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Buttons from "../../General/Component/Buttons";
import { useEffect, useState } from "react";
import DialogFormPopUp from "../../General/Component/DialogFormPopUp";
import ContactSection from "../../General/Component/ContactSection";
import LeftArrow from "../../General/Icon/LeftArrow";
import RightIcon from "../../General/Icon/RightIcon";
import { CardInformation } from "../../General/Component/CardInformation";
import getVerificationStatus from "../Helper/getVerificationStatus";
import { LoadingState } from "../../General/Component/LoadingComp";
import useSnackbar from "../../General/Helper/useSnackbar";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import getCredentialStaging from "../Helper/getCredentialStaging";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid #CFDFEC",
    // borderBottom: "1px solid #CFDFEC",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FCFCFC",
  },
}));

const WebStaging = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const [isStaging, setIsStaging] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataStaging, setDataStaging] = useState([]);
  const [statusStaging, setStatusStaging] = useState(null);

  const handleCloseContactUs = () => {
    setOpenContactUs(false);
  };

  useEffect(() => {
    getVerificationStatus(
      setLoading,
      setIsStaging,
      setActiveStepOutlined,
      setActiveSectionNumber,
      t,
      strings,
      showSnackbar,
      setStatusStaging
    );
  }, []);

  useEffect(() => {
    if (statusStaging) {
      getCredentialStaging(
        setLoading,
        setIsStaging,
        setDataStaging,
        t,
        strings,
        showSnackbar
      );
    }
  }, [statusStaging]);

  const onSubmitVerif = () => {
    setActiveStepOutlined((prev) => prev + 1);
    setActiveSectionNumber(0);
  };

  const handlePreviousSection = () => {
    setActiveStepOutlined((prev) => prev - 1);
    setActiveSectionNumber(0);
  };
  return !loading ? (
    <>
      <div className={classes.divFormStepper}>
        <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
        <DialogFormPopUp
          open={openContactUs}
          onClose={handleCloseContactUs}
          maxWidth={"xs"}
        />
        <div className={classes.divWebStaging}>
          {isStaging ? (
            <>
              <div>
                <Typography className={classes.textInfoMerchantWs}>
                  {t(strings.textInformasiAccountMerchant)}
                </Typography>
                <Typography className={classes.subTextInfoMerchantWs}>
                  {t(strings.textInfoMerchantAndWs)}
                </Typography>
              </div>
              <div>
                <TableContainer component={Paper} className={classes.divTable}>
                  <Table
                    sx={{
                      "& .MuiTableContainer-root": {
                        boxShadow: "0px 0px 0px",
                      },
                    }}
                    aria-label="custom styled table"
                  >
                    <TableBody>
                      {dataStaging.map((row) => (
                        <StyledTableRow key={row.label}>
                          <StyledTableCell className={classes.cellKey}>
                            <Typography className={classes.textKey}>
                              {row.label}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography className={classes.textValue}>
                              {row.value}
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div>
                <Typography className={classes.textReqProd}>
                  {t(strings.textPleaseClickToReqProd)}
                </Typography>
                <div style={{ width: "180px" }}>
                  <Buttons
                    loadingSubmit={false}
                    label={t(strings.textReqProd)}
                    handleDisabledButton={() => false}
                    onSubmitData={() => null}
                    rightIcon={null}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <CardInformation
                header={t(strings.WebStgCredenCreation)}
                text={t(strings.textCreationWebStginProgress)}
                status={"waiting"}
              />
              <Buttons
                loadingSubmit={false}
                label={t(strings.textCheckProses)}
                handleDisabledButton={() => false}
                onSubmitData={() =>
                  getVerificationStatus(
                    setLoading,
                    setIsStaging,
                    setActiveStepOutlined,
                    setActiveSectionNumber,
                    t,
                    strings,
                    showSnackbar,
                    setStatusStaging
                  )
                }
                rightIcon={null}
              />
              <ContactSection
                t={t}
                title={t(strings.textHaveAQuestion)}
                subtitle={t(strings.textContactViaWaandEmail)}
              />
            </>
          )}
        </div>

        {/* nanti ganti aja ya. ini testing untuk steps controller aja */}
        {/* Buttons ini hanya untuk testing. Aslinya ga ada */}
        <div className={classes.divGroupButton}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textPrevious)}
            handleDisabledButton={() => false}
            onSubmitData={handlePreviousSection}
            leftIcon={<LeftArrow />}
            isSolid={false}
          />
          <Buttons
            loadingSubmit={false}
            label={t(strings.textNext)}
            handleDisabledButton={() => false}
            onSubmitData={onSubmitVerif}
            rightIcon={<RightIcon />}
          />
        </div>
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default WebStaging;
