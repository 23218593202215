// validationSchemas.js
import { z } from "zod";
import strings from "../Constant/Locales/Strings/GeneralStrings";

const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}$/;
const regularRegex = /^[a-zA-Z\s.'-]+$/;
const phoneNumberRegex = /^(?:\+62)?\d{10,13}$/;
const alphanumericRegex = /^[A-Za-z0-9]+$/;
const alphanumericWithSpaceRegex = /^[A-Za-z0-9 ]+$/;
const alphanumericWithSpaceAndDashRegex = /^[A-Za-z0-9 -]+$/;
const urlRegex = /^https?:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
const swiftRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

export const FieldRequired = (t, label) =>
  z.string().min(1, label + " " + t(strings.textIsRequired));

export const EmailSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .email(t(strings.textValidationEmailFormat));

export const PhoneNumberSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .refine((value) => phoneNumberRegex.test(value), {
      message: t(strings.textValidationPhoneNumberFormat),
    });

export const RegularSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .refine((value) => regularRegex.test(value), {
      message: label + " " + t(strings.textValidationLettersAndSomeSymbols),
    });

export const PasswordSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .refine(
      (value) => {
        return passwordRegex.test(value);
      },
      { message: t(strings.textValidationPassword) }
    );

export const NPWPSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(/^\d+$/, {
      message: label + " " + t(strings.textValidationOnlyNumber),
    })
    .refine((value) => value.length === 15 || value.length === 16, {
      message: label + " " + t(strings.textMustBe15Or16Digits),
    });

export const IDCardSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(/^\d+$/, {
      message: label + " " + t(strings.textValidationOnlyNumber),
    })
    .refine((value) => value.length === 16, {
      message: label + " " + t(strings.textMustBe16Digits),
    });

export const URLSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .refine((url) => urlRegex.test(url), {
      message: t(strings.textValidationURL),
    });

export const PassportSchema = (t, label) =>
  z
    .string()
    .min(6, label + " " + t(strings.textMustMin6chars))
    .max(9, label + " " + t(strings.textMustMax9chars))
    .regex(alphanumericRegex, label + " " + t(strings.textMustAlphanumeric));

export const OnlyNumberSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(/^\d+$/, label + " " + t(strings.textValidationOnlyNumber));

export const AmountSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(
      /^\d+(\.\d{1,2})?$/,
      label + " " + t(strings.textValidationOnlyNumber)
    );

export const AddressSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .max(100, label + " " + t(strings.textMustMax100chars));

export const SwiftNumberSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(swiftRegex, label + " " + t(strings.textValidationInvalid));

export const AlphanumericWithSpaceSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .max(9, label + " " + t(strings.textMustMax9chars))
    .regex(
      alphanumericWithSpaceRegex,
      label + " " + t(strings.textMustAlphanumeric)
    );

export const AlphanumericWithDashSpaceSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .regex(
      alphanumericWithSpaceAndDashRegex,
      label + " " + t(strings.textValidationLettersNumbersAndSomeSymbol)
    );
