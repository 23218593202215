import { useTranslation } from "react-i18next";
import Buttons from "../../General/Component/Buttons";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SelectOptions from "../../General/Component/SelectOptions";
import listCategory from "../Constant/listCategory";
import listIntegrationService from "../Constant/listIntegrationService";
import objectPaymentService from "../Constant/objectPaymentService";
import { Typography } from "@mui/material";
import listPaymentChannel from "../Constant/listPaymentChannel";
import listPaymentCategory from "../Constant/listPaymentCategory";
import RightIcon from "../../General/Icon/RightIcon";
import LeftArrow from "../../General/Icon/LeftArrow";
import CheckBox from "../../General/Component/CheckBox";
import FormCheckBox from "../../General/Component/FormCheckBox";
import listDisbursementDetails from "../Constant/listDisbursementDetails";
import onSubmitMerchantInfo from "../Helper/onSubmitMerchantInfo";
import getMerchantDetail from "../Helper/getMerchantDetail";
import { LoadingState } from "../../General/Component/LoadingComp";
import Textfields from "../../General/Component/Textfields";

const FormPaymentInfo = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [merchantDetail, setMerchantDetail] = useState(null);
  const [loadingMerchant, setLoadingMerchant] = useState(true);
  const [formData, setFormData] = useState({
    serviceCategory: "",
    notes: "",
    isDisbursement: false,
    loadingSubmit: false,
  });
  const [serviceDetails, setServiceDetails] = useState(listIntegrationService);
  const [channels, setChannels] = useState(listPaymentChannel);
  const [disbursementServiceDetails, setDisbursementServiceDetails] = useState(
    listDisbursementDetails
  );
  const [isDisbursementOpen, setIsDisbursementOpen] = useState(false);

  useEffect(() => {
    getMerchantDetail(
      t,
      strings,
      activeSectionNumber,
      activeStepOutlined,
      setMerchantDetail,
      setLoadingMerchant,
      showSnackbar
    );
  }, [activeSectionNumber, activeStepOutlined, showSnackbar, t]);

  useEffect(() => {
    if (formData.serviceCategory) {
      let data = [];
      listIntegrationService.forEach((item) => {
        if (formData.serviceCategory === "API Integration") {
          if (
            item.name === "Api Payment Gateway" ||
            item.name === "Api Payment Page"
          ) {
            data.push(item);
          }
        } else if (formData.serviceCategory === "Non Integration") {
          if (
            item.name === "Instapay Dashboard" ||
            item.name === "Email Invoice"
          ) {
            data.push(item);
          }
        }
      });
      if (merchantDetail?.merchantServiceDetails.length > 0) {
        const check = data.map((service) => {
          const isChecked = merchantDetail.merchantServiceDetails.some(
            (merchantService) =>
              merchantService.serviceDetailName === service.name
          );
          return { ...service, checked: isChecked };
        });
        setServiceDetails(check);
      } else {
        setServiceDetails(data);
      }
    }
  }, [formData.serviceCategory, merchantDetail]);

  useEffect(() => {
    if (merchantDetail) {
      const {
        serviceCategory,
        isDisbursement,
        merchantChannels,
        merchantServiceDetails,
        notes,
      } = merchantDetail;

      const updatedPaymentChannels = listPaymentChannel.map((channel) => {
        const isChecked = merchantChannels.some(
          (merchantChannel) => merchantChannel.channelName === channel.name
        );
        return { ...channel, checked: isChecked };
      });

      const updateDisbursementService = listDisbursementDetails.map(
        (disburse) => {
          const isChecked = merchantServiceDetails.some(
            (disburseService) =>
              disburseService.serviceDetailName === disburse.name
          );
          return { ...disburse, checked: isChecked };
        }
      );

      if (serviceCategory === "Disbursement Only") {
        setIsDisbursementOpen(true);
      }
      setChannels(updatedPaymentChannels);
      setDisbursementServiceDetails(updateDisbursementService);

      setFormData((prev) => {
        if (
          prev.serviceCategory !== serviceCategory ||
          prev.isDisbursement !== isDisbursement
        ) {
          return {
            ...prev,
            serviceCategory: serviceCategory,
            isDisbursement: isDisbursement,
            notes: notes,
          };
        }
        return prev;
      });
    }
  }, [merchantDetail]);

  useEffect(() => {
    calculateProgressValue();
    showDisbursementGateway();
    handleDisabledButton();
  }, [formData, serviceDetails, channels, disbursementServiceDetails]);

  const handlePreviousStep = () => {
    setActiveSectionNumber((prev) => prev - 1);
  };

  const handleChange = (e, nameCheckbox) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
      if (name === "serviceCategory" && value === "Disbursement Only") {
        setIsDisbursementOpen(true);
        setFormData((prev) => ({ ...prev, isDisbursement: true }));
      } else {
        setIsDisbursementOpen(false);
        setFormData((prev) => ({ ...prev, isDisbursement: false }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [nameCheckbox]: e,
      }));
    }
  };

  const handleServiceDetailChange = (isChecked, name) => {
    setServiceDetails((prevState) =>
      prevState?.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, checked: isChecked } : checkbox
      )
    );
  };

  const handleChannelsChange = (isChecked, name) => {
    setChannels((prevState) =>
      prevState?.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, checked: isChecked } : checkbox
      )
    );
  };

  const handleDisbursementDetailsChange = (isChecked, name) => {
    setDisbursementServiceDetails((prevState) =>
      prevState?.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, checked: isChecked } : checkbox
      )
    );
  };

  const calculateProgressValue = () => {
    let serviceItem = serviceDetails?.filter((data) => data.checked).length;
    let channelItem = channels?.filter((data) => data.checked).length;
    let disbursementItem = disbursementServiceDetails.filter(
      (data) => data.checked
    ).length;

    let progressConditions = [formData.serviceCategory];

    if (formData.serviceCategory === "Disbursement Only") {
      if (formData.isDisbursement) {
        progressConditions.push(formData.isDisbursement, disbursementItem);
      }
    } else {
      progressConditions.push(serviceItem > 0, channelItem > 0);
      if (formData.isDisbursement) {
        progressConditions.push(formData.isDisbursement, disbursementItem);
      }
    }

    const validProgressCount = progressConditions.filter(Boolean).length;
    const progressNumber =
      (validProgressCount / progressConditions.length) * 20;

    setProgressData((prev) => ({
      ...prev,
      section4: Math.ceil(progressNumber),
    }));
  };

  const showFormIntegration = (field, index) => {
    return (
      <div key={index} className={classes.divFormService}>
        <CheckBox
          key={index}
          name={field}
          title={t(strings.textSelectIntegrationService)}
          options={serviceDetails}
          columnsPerRow={1}
          handleChange={handleServiceDetailChange}
        />
        <div className={classes.divLine} />
      </div>
    );
  };

  const showChannels = (field, index) => {
    const filterPaymentListById = (paymentChannels, id) =>
      paymentChannels
        .filter((item) => item.id === id)
        .map((data) => ({
          ...data,
        }));
    const paymentChannels = channels;
    const creditCardList = filterPaymentListById(
      paymentChannels,
      "Credit Card"
    );
    const virtualAccountList = filterPaymentListById(
      paymentChannels,
      "Virtual Account"
    );
    const walletList = filterPaymentListById(paymentChannels, "Wallet");
    const OTCList = filterPaymentListById(
      paymentChannels,
      "Over The Counter (OTC)"
    );

    return (
      <div key={index} className={classes.divFormService}>
        <div>
          <Typography className={classes.textSuperBold18}>
            {t(strings.textSelectPaymentChannel)}
          </Typography>
          <Typography className={classes.textRegularDarkGray14}>
            {t(strings.textSelectPaymentChannelSubtitle)}
          </Typography>
        </div>

        <div className={classes.divObjectPayment}>
          {listPaymentCategory(t, strings).map((data, idx) => {
            const keyId = `${data.id}-${idx}`;

            const categoryOptions = {
              "Credit Card": creditCardList,
              "Virtual Account": virtualAccountList,
              Wallet: walletList,
              OTC: OTCList,
            };

            const titles = {
              "Credit Card": t(strings.textCreditCard),
              "Virtual Account": t(strings.textVirtualCard),
              Wallet: t(strings.textWallet),
              OTC: t(strings.textOTC),
            };

            return categoryOptions[data.id] ? (
              <CheckBox
                key={keyId}
                title={titles[data.id]}
                name={field}
                options={categoryOptions[data.id]}
                handleChange={handleChannelsChange}
                columnsPerRow={3}
                subTitle={t(strings.textIncludeVAT)}
                notes={
                  data.id === "Virtual Account"
                    ? t(strings.textMustHaveBCAVirtualAccount)
                    : ""
                }
              />
            ) : null;
          })}
        </div>
      </div>
    );
  };

  const handleDisabledButton = () => {
    const resultServiceDetails =
      serviceDetails.filter((data) => data.checked).map((data) => data.name) ||
      [];
    const resultChannels = channels
      .filter((data) => data.checked)
      .map((data) => ({
        merchantChannelType: data.id,
        channelName: data.name,
      }));

    return (
      formData.loadingSubmit ||
      !formData.serviceCategory ||
      resultServiceDetails?.length < 1 ||
      resultChannels.length < 1
    );
  };

  const handleDisabledButtonDisburse = () => {
    const resultDisbursement =
      disbursementServiceDetails
        .filter((data) => data.checked)
        .map((data) => data.name) || [];

    return formData.isDisbursement && resultDisbursement.length < 1
      ? true
      : false;
  };

  const goToDisbursement = () => {
    if (formData.serviceCategory === "Disbursement Only") {
      handlePreviousStep();
    } else {
      setIsDisbursementOpen(!isDisbursementOpen);
    }
  };

  const showDisbursementGateway = () => {
    if (
      formData.serviceCategory === "API Integration" ||
      formData.serviceCategory === "Non Integration"
    ) {
      return (
        <>
          <div className={classes.divRowSpace}>
            <Typography className={classes.textBold600Darker16}>
              {t(strings.textAskForDisbursement)}
            </Typography>
            <FormCheckBox
              checked={formData?.isDisbursement}
              label={""}
              columnsPerRow={1}
              name={"isDisbursement"}
              onChange={handleChange}
              icon={null}
            />
          </div>
          {formData.isDisbursement && (
            <CheckBox
              name={"disbursementServiceDetails"}
              title={t(strings.textSelectDisbursementService)}
              options={disbursementServiceDetails}
              columnsPerRow={1}
              handleChange={handleDisbursementDetailsChange}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {objectPaymentService(t, strings).map((field, index) => {
            switch (field.name) {
              case "serviceCategory":
                return (
                  <SelectOptions
                    key={index}
                    label={field.label}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={formData[field.name]}
                    listData={listCategory(t, strings)}
                    handleChange={handleChange}
                  />
                );

              case "disbursementServiceDetails":
                return (
                  <CheckBox
                    key={index}
                    name={field.name}
                    title={t(strings.textSelectDisbursementService)}
                    options={disbursementServiceDetails}
                    columnsPerRow={1}
                    handleChange={handleDisbursementDetailsChange}
                  />
                );

              default:
                return null;
            }
          })}
        </>
      );
    }
  };

  const onSubmitPaymentInfo = () => {
    const resultServiceDetails =
      serviceDetails.filter((data) => data.checked).map((data) => data.name) ||
      [];
    const resultChannels = channels
      .filter((data) => data.checked)
      .map((data) => ({
        merchantChannelType: data.id,
        channelName: data.name,
      }));
    const resultDisbursement =
      disbursementServiceDetails
        .filter((data) => data.checked)
        .map((data) => data.name) || [];

    const payload = {
      businessType: userDataLogin?.business_type,
      serviceCategory: formData.serviceCategory,
      serviceDetails: resultServiceDetails,
      isDisbursement: formData.isDisbursement,
      disbursementServiceDetails: resultDisbursement,
      channels:
        formData.serviceCategory === "Disbursement Only" ? [] : resultChannels,
      notes: formData.notes,
      section: activeSectionNumber + 1,
      serviceFeeCompletion: progressData.section4,
      totalCompletion: 20,
    };

    onSubmitMerchantInfo(
      t,
      strings,
      payload,
      activeSectionNumber,
      setActiveSectionNumber,
      activeStepOutlined,
      setActiveStepOutlined,
      setFormData,
      showSnackbar
    );
  };

  return !loadingMerchant ? (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <div className={classes.divFormStepper}>
        {!isDisbursementOpen ? (
          <>
            {objectPaymentService(t, strings).map((field, index) => {
              switch (field.name) {
                case "serviceCategory":
                  return (
                    <SelectOptions
                      key={index}
                      label={field.label}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      listData={listCategory(t, strings)}
                      handleChange={handleChange}
                    />
                  );
                case "serviceDetails":
                  return (
                    (formData.serviceCategory === "API Integration" ||
                      formData.serviceCategory === "Non Integration") &&
                    showFormIntegration(field, index)
                  );

                case "channels":
                  return (
                    (formData.serviceCategory === "API Integration" ||
                      formData.serviceCategory === "Non Integration") &&
                    showChannels(field, index)
                  );

                case "notes":
                  return (
                    <Textfields
                      key={index}
                      placeholder={field.placeholder}
                      name={field.name}
                      label={field.label}
                      type={field.type}
                      value={formData[field.name]}
                      onChange={handleChange}
                      isOptional={field.isOptional}
                      isHideLabel={field.isHideLabel}
                      isValidate={field.isValidate}
                    />
                  );
                default:
                  return null;
              }
            })}

            <div className={classes.divGroupButton}>
              <Buttons
                loadingSubmit={false}
                label={t(strings.textPrevious)}
                handleDisabledButton={() => false}
                onSubmitData={handlePreviousStep}
                leftIcon={<LeftArrow />}
                isSolid={false}
              />
              <Buttons
                loadingSubmit={formData.loadingSubmit}
                label={t(strings.textNext)}
                handleDisabledButton={handleDisabledButton}
                onSubmitData={goToDisbursement}
                rightIcon={<RightIcon />}
              />
            </div>
          </>
        ) : (
          <>
            {showDisbursementGateway()}
            <div className={classes.divGroupButton}>
              <Buttons
                loadingSubmit={false}
                label={t(strings.textPrevious)}
                handleDisabledButton={() => false}
                onSubmitData={goToDisbursement}
                leftIcon={<LeftArrow />}
                isSolid={false}
              />
              <Buttons
                loadingSubmit={formData.loadingSubmit}
                label={t(strings.textNext)}
                handleDisabledButton={handleDisabledButtonDisburse}
                onSubmitData={onSubmitPaymentInfo}
                rightIcon={<RightIcon />}
              />
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default FormPaymentInfo;
