import FileUpload from "../../General/Component/FileUpload";

export const renderSupportingDoc = (
  classes,
  isCorporate,
  isWNA,
  field,
  index,
  formData,
  handleChange,
  showSnackbar,
  handleCloseFile
) => {
  const wnaField = [
    "filePassportDirector",
    "fileKTPPIC",
    "fileBankNumber",
    "fileNPWP",
    "fileTDPSE",
    "fileDeedEstablishment",
    "fileSKKemenkumham",
    "fileNIB",
    "fileInstitutionPermit",
  ];

  const wniField = [
    "fileDirectorKTP",
    "fileBankNumber",
    "fileNPWP",
    "fileTDPSE",
    "fileDeedEstablishment",
    "fileSKKemenkumham",
    "fileNIB",
    "fileInstitutionPermit",
  ];

  const nonCorporate = [
    "fileKTPPerson",
    "fileBankNumber",
    "fileNPWP",
    "fileNIB",
  ];

  const fieldsToCheck = isCorporate
    ? isWNA
      ? wnaField
      : wniField
    : nonCorporate;

  if (fieldsToCheck.includes(field.name)) {
    return (
      <div className={classes.divHalf} key={index}>
        <FileUpload
          key={index}
          name={field.name}
          label={field.label}
          fileName={formData[field.name]?.fileName}
          placeholder={field.placeholder}
          icon={field.icon}
          extensionFile={field.extensionFile}
          notes={field.notes}
          isOptional={field.isOptional}
          onChange={handleChange}
          showSnackbar={showSnackbar}
          handleClose={handleCloseFile}
        />
      </div>
    );
  }
};
