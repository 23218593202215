import React from "react";
import { Snackbar } from "@mui/material";
import InfoComponent from "./InfoComponent";

const SnackbarNotification = ({ snackbar, hideSnackbar }) => {
  const { open, message, severity } = snackbar || {};

  return (
    <Snackbar
      open={Boolean(open)}
      autoHideDuration={5000}
      onClose={hideSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <InfoComponent severity={severity} message={message} />
    </Snackbar>
  );
};

export default SnackbarNotification;
