const objectBankAccount = (t, strings) => [
  {
    name: "currency",
    placeholder: "",
    label: t(strings.textCurrency),
    type: "radio",
    isValidate: true,
  },
  {
    name: "bankName",
    placeholder: t(strings.textPlaceholderBankName),
    label: t(strings.textBankName),
    type: "text",
    isValidate: true,
  },
  {
    name: "bankBranchName",
    placeholder: t(strings.textPlaceholderBankBranch),
    label: t(strings.textBankBranch),
    type: "text",
    isValidate: true,
  },
  {
    name: "bankSwiftNumber",
    placeholder: t(strings.textPlaceholderBankSwiftCode),
    label: t(strings.textBankSwiftCode),
    type: "text",
    isValidate: true,
  },
  {
    name: "bankAccountName",
    placeholder: t(strings.textPlaceholderBankAccountName),
    label: t(strings.textBankAccountName),
    type: "text",
    isValidate: true,
  },
  {
    name: "bankNumber",
    placeholder: t(strings.textPlaceholderBankNumber),
    label: t(strings.textBankNumber),
    type: "text",
    isValidate: true,
  },
];

export default objectBankAccount;
