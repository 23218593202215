const listOutlinedStep = (t, strings) => [
  {
    id: 1,
    label: t(strings.textMerchantRegistration),
    description: "Description",
  },
  {
    id: 2,
    label: t(strings.textSupportingDocuments),
    description: "Description",
  },
  { id: 3, label: t(strings.textWebStaging), description: "Description" },
  {
    id: 4,
    label: t(strings.textVerificationProcess),
    description: "Description",
  },
  {
    id: 5,
    label: t(strings.textActivationStatus),
    description: "Description",
  },
];

export default listOutlinedStep;
