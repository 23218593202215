import React from "react";
import { Box, Typography } from "@mui/material";
import classes from "../Style/general.module.css";
import ErrorIcon from "../Icon/ErrorIcon";
import Buttons from "./Buttons";
import strings from "../Constant/Locales/Strings/RegistMerchantStrings";

const VerificationFailed = ({ t }) => {
  return (
    <>
      <Box className={classes.verifFailedHeader}>
        <Box className={classes.verifFailedAlertContainer}>
          <Box className={classes.verifFailedIconContainer}>
            <ErrorIcon widthIcon={"100%"} heightIcon={"100%"} />
          </Box>
        </Box>
        <Typography className={classes.textSuperBold18}>
          {t(strings.verificationFailed)}
        </Typography>
      </Box>
      <Box className={classes.verifFailedCard}>
        <Typography
          sx={{
            alignSelf: "stretch",
          }}
          className={classes.textBold600BlackH4}
        >
          {t(strings.verificationFailureReason)}
        </Typography>

        <div className={classes.textRegularDarkGray14}>
          <Typography>{t(strings.missingDocumentsInfo)}</Typography>
          <ul style={{ paddingLeft: "15px" }}>
            <li>{t(strings.tdPseDocumentUnreadable)}</li>
            <li>{t(strings.nibDocumentNotUpdated)}</li>
          </ul>
          <Typography>{t(strings.furtherInformation)}</Typography>
        </div>
        <Buttons
          loadingSubmit={false}
          label={t(strings.retryProcess)}
          isSolid={true}
          handleDisabledButton={() => null}
          onSubmitData={() => null}
        />
      </Box>
    </>
  );
};

export default VerificationFailed;
