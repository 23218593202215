import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getMerchantDetail = (
  t,
  strings,
  activeSectionNumber,
  activeStepOutlined,
  setMerchantDetail,
  setLoadingMerchant,
  showSnackbar
) => {
  setLoadingMerchant(true);
  const headers = headerRequest();
  RequestHttpGateway.get(
    `merchant/detail?step=${Number(activeStepOutlined + 1)}&section=${Number(
      activeSectionNumber + 1
    )}`,
    {
      headers,
    }
  )
    .then((res) => {
      setMerchantDetail(res.data.data);
    })
    .catch((err) => {
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    })
    .finally(() => {
      setLoadingMerchant(false);
    });
};

export default getMerchantDetail;
