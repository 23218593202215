import { IconButton, TextField, Tooltip, Typography } from "@mui/material";
import VisibleIcon from "../Icon/VisibleIcon";
import VisibilityOffIcon from "../Icon/VisibilityOffIcon";
import classes from "../Style/general.module.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import strings from "../Constant/Locales/Strings/GeneralStrings";
import handleChangeTextfield from "../Helper/handleChangeTextfield";

const PhoneAdornment = () => (
  <div className={classes.divPhoneAdornment}>
    <Typography>+62</Typography>
    <div className={classes.divLine} />
  </div>
);

const PasswordAdornment = ({ isVisible, toggleVisibility, tooltipText }) => (
  <Tooltip title={tooltipText}>
    <IconButton onClick={toggleVisibility}>
      {isVisible ? <VisibilityOffIcon /> : <VisibleIcon />}
    </IconButton>
  </Tooltip>
);

const Textfields = ({
  isHideLabel,
  placeholder,
  name,
  label,
  type = "text",
  value,
  onChange,
  error,
  helperText,
  fullWidth = true,
  isValidate = true,
  isOptional = false,
  isAny = false,
  ...props
}) => {
  const { t } = useTranslation("General");
  const [isVisible, setIsVisible] = useState(false);
  const [validationError, setValidationError] = useState("");

  const handleVisibilityToggle = () => setIsVisible(!isVisible);

  const handleChange = (event) => {
    handleChangeTextfield(
      t,
      event,
      name,
      setValidationError,
      onChange,
      label,
      isValidate
    );
  };

  const startIconAdornment = () => {
    if (name?.toLowerCase().includes("phone")) {
      return <PhoneAdornment />;
    } else return null;
  };

  return (
    <div className={classes.divFormField}>
      <div className={classes.divTypoField}>
        <Typography className={classes.textBold600BlackH4}>
          {label}
          {isOptional ? (
            <span
              className={classes.textGrayItalic12}
              style={{ margin: "0 0 0 4px" }}
            >
              {t(strings.textOptional)}
            </span>
          ) : isAny ? (
            <span
              className={classes.textGrayItalic12}
              style={{ margin: "0 0 0 4px" }}
            >
              {t(strings.textIsAny)}
            </span>
          ) : null}
        </Typography>
      </div>
      <TextField
        placeholder={placeholder || ""}
        name={name || ""}
        label={isHideLabel ? null : label}
        type={type === "password" ? (isVisible ? "text" : "password") : type}
        value={value || ""}
        onChange={handleChange}
        error={error || Boolean(validationError)}
        helperText={helperText || validationError}
        multiline={
          name?.toLowerCase().includes("address") ||
          name?.toLowerCase().includes("note") ||
          name?.toLowerCase().includes("message")
            ? true
            : false
        }
        rows={
          name?.toLowerCase().includes("address") ||
          name?.toLowerCase().includes("note") ||
          name?.toLowerCase().includes("message")
            ? 4
            : 1
        }
        fullWidth={fullWidth}
        variant="outlined"
        {...props}
        InputProps={{
          // startAdornment: startIconAdornment(), //need to develop in the next section
          endAdornment: type === "password" && (
            <PasswordAdornment
              isVisible={isVisible}
              toggleVisibility={handleVisibilityToggle}
              tooltipText={
                isVisible ? t(strings.textHide) : t(strings.textView)
              }
            />
          ),
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: name?.toLowerCase().includes("file")
                ? "1px dashed #CFDFEC"
                : "1px solid #cfdfec",
              display: "flex",
              padding: "14px 12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              fontSize: "14px",
            },
          },
        }}
      />
    </div>
  );
};

export default Textfields;
