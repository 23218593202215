import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getVerificationStatus = (
  setLoading,
  setIsStaging,
  setActiveStepOutlined,
  setActiveSectionNumber,
  t,
  strings,
  showSnackbar,
  setStatusStaging
) => {
  const headers = headerRequest();
  setLoading(true);
  RequestHttpGateway.get(`/merchant/verification-status`, { headers })
    .then((res) => {
      if (res.data.staging === null) {
        // back to preview data
        setActiveStepOutlined((prev) => prev - 1);
        setActiveSectionNumber(1);
        setLoading(false);
      } else if (res.data.staging === "DONE" || res.data.staging === "done") {
        setStatusStaging(true);
      } else {
        setLoading(false);
        setIsStaging(false);
      }
    })
    .catch((err) => {
      setLoading(false);
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    });
};

export default getVerificationStatus;
