import { Button, CircularProgress } from "@mui/material";
import classes from "../Style/general.module.css";

const Buttons = ({
  loadingSubmit,
  label,
  leftIcon,
  rightIcon,
  isSolid = true,
  handleDisabledButton,
  onSubmitData,
}) => {
  const isDisabled = loadingSubmit || handleDisabledButton();

  const buttonClass = isDisabled
    ? `${classes.buttonSubmitInactive} ${classes.textBold600Gray}`
    : isSolid
    ? `${classes.buttonSubmit} ${classes.textBold600Darker}`
    : `${classes.buttonSubmitOutlined} ${classes.textBold600H5}`;

  return (
    <Button
      fullWidth
      disabled={isDisabled}
      variant={"contained"}
      className={buttonClass}
      onClick={onSubmitData}
    >
      <span className={classes.divAllCenter} style={{ gap: "8px" }}>
        {loadingSubmit && <CircularProgress size={"1em"} />}
        {leftIcon}
        {label}
        {rightIcon}
      </span>
    </Button>
  );
};

export default Buttons;
