const CloseBlueIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 18}
    height={heightIcon || 18}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M14.4608 13.6644C14.513 13.7166 14.5545 13.7787 14.5828 13.8469C14.6111 13.9152 14.6256 13.9884 14.6256 14.0623C14.6256 14.1362 14.6111 14.2094 14.5828 14.2777C14.5545 14.346 14.513 14.408 14.4608 14.4603C14.4085 14.5126 14.3465 14.554 14.2782 14.5823C14.2099 14.6106 14.1367 14.6251 14.0628 14.6251C13.9889 14.6251 13.9157 14.6106 13.8474 14.5823C13.7792 14.554 13.7171 14.5126 13.6648 14.4603L9.00031 9.79506L4.33578 14.4603C4.23023 14.5658 4.08708 14.6251 3.93781 14.6251C3.78855 14.6251 3.64539 14.5658 3.53984 14.4603C3.4343 14.3547 3.375 14.2116 3.375 14.0623C3.375 13.9131 3.4343 13.7699 3.53984 13.6644L8.20508 8.99982L3.53984 4.33529C3.4343 4.22975 3.375 4.08659 3.375 3.93732C3.375 3.78806 3.4343 3.6449 3.53984 3.53936C3.64539 3.43381 3.78855 3.37451 3.93781 3.37451C4.08708 3.37451 4.23023 3.43381 4.33578 3.53936L9.00031 8.20459L13.6648 3.53936C13.7704 3.43381 13.9135 3.37451 14.0628 3.37451C14.2121 3.37451 14.3552 3.43381 14.4608 3.53936C14.5663 3.6449 14.6256 3.78806 14.6256 3.93732C14.6256 4.08659 14.5663 4.22975 14.4608 4.33529L9.79555 8.99982L14.4608 13.6644Z"
      fill="#1677FF"
    />
  </svg>
);
export default CloseBlueIcon;
