import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderComponent from "./HeaderComponent";
import strings from "../Constant/Locales/Strings/GeneralStrings";
import classes from "../Style/general.module.css";

const PageWithIllustration = ({
  illustration,
  textTitle,
  textSubTitle,
  textErrorCode,
  buttonAction,
}) => {
  const { t } = useTranslation("General");
  return (
    <Grid>
      <HeaderComponent />
      <div className={classes.divContainer}>
        <div className={classes.divImageIllustration}>
          <img src={illustration} className={classes.illustration} alt="" />
        </div>
        <div className={classes.divTextIllustration}>
          <Typography className={classes.textBold32}>{textTitle}</Typography>
          <Typography className={classes.textGraySemiBold60}>
            {textSubTitle}
          </Typography>
          <Typography className={classes.textGray14Regular60}>
            {textErrorCode}
          </Typography>

          {buttonAction}
        </div>
      </div>
      <div className={classes.divFooter}>
        <Typography className={classes.styleCopyRight}>
          {t(strings.textCopyright)}
        </Typography>
      </div>
    </Grid>
  );
};

export default PageWithIllustration;
