import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import classes from "../Style/general.module.css";
import CheckIcon from "../Icon/CheckIcon";

const CustomStepConnector = styled(StepConnector)({
  [`& .MuiStepConnector-line`]: {
    borderColor: "#F6F4F5",
    borderWidth: "100%",
    height: "100%",
  },
});

const StepIconDots = ({ active, completed }) => (
  <div
    className={`${classes.stepIconWrapper} ${
      active ? classes.stepIconActive : ""
    } ${completed ? classes.stepIconCompleted : ""}`}
  >
    <div className={classes.outerCircle}></div>
    <div className={classes.innerCircle}>
      {completed && <CheckIcon widthIcon={"100%"} heightIcon={20} />}
    </div>
  </div>
);

const StepIconNumber = ({ active, completed, icon }) => (
  <div
    className={`${classes.stepIconWrapperNumber} ${
      active ? classes.stepIconActive : ""
    } ${completed ? classes.stepIconCompleted : ""}`}
  >
    <div className={classes.outerCircle}></div>
    <div className={classes.innerCircleNumber}>
      {completed ? (
        <CheckIcon />
      ) : (
        <Typography
          className={
            active ? classes.textBold600Darker : classes.textGray14SemiBold600
          }
        >
          {icon}
        </Typography>
      )}
    </div>
  </div>
);

const getTitleClass = (index, activeStep, dots) =>
  dots
    ? activeStep === index
      ? classes.textBold600Darker
      : classes.textGray14Bold600
    : activeStep === index
    ? classes.textLight14Semi
    : classes.textGray14SemiBold600;

const Steppers = ({ activeStep, stepList, orientationStepper, dots }) => (
  <Stepper
    className={classes.stepper}
    activeStep={activeStep}
    orientation={orientationStepper}
    alternativeLabel={!dots}
    connector={<CustomStepConnector />}
  >
    {stepList.map((data, index) => (
      <Step key={index}>
        <StepLabel StepIconComponent={dots ? StepIconDots : StepIconNumber}>
          <Typography className={getTitleClass(index, activeStep, dots)}>
            {data.label}
          </Typography>
          {data.description && (
            <Typography className={classes.textGray14Regular600}>
              {data.description}
            </Typography>
          )}
        </StepLabel>
      </Step>
    ))}
  </Stepper>
);

export default Steppers;
