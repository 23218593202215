import RequestHttpGateway from "./RequestHttpGateway";
import headerRequest from "./headerRequest";

const sendMessageToEmail = (
  setFormData,
  formData,
  showSnackbar,
  t,
  strings,
  handleDialogClose
) => {
  setFormData((prev) => ({ ...prev, loadingSubmit: true }));
  const bodyRequest = {
    fullname: formData.fullName,
    email: formData.email,
    type: formData.subject,
    merchantName: formData.companyName,
    phone: formData.phoneNumber,
    message: formData.message,
  };

  const headers = headerRequest();
  RequestHttpGateway.post(`/email/contact-us`, bodyRequest, {
    headers,
  })
    .then((res) => {
      showSnackbar("Laporan telah berhasil dikirim melalui email.", "success");
      setFormData((prev) => ({ ...prev, activeStep: 1 }));
      handleDialogClose();
    })
    .catch((err) => {
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    })
    .finally(() => {
      setFormData((prev) => ({ ...prev, loadingSubmit: false }));
    });
};

export default sendMessageToEmail;
