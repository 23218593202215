import UploadIcon from "../../General/Icon/UploadIcon";

const objectSupportingDoc = (t, strings, isCorporate) => [
  {
    name: "fileDirectorKTP",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: t(strings.textDirectorEKTP),
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: false,
  },
  {
    name: "fileKTPPerson",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: "KTP",
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: false,
  },
  {
    name: "filePassportDirector",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: t(strings.textPassportDirector),
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: false,
  },
  {
    name: "fileKTPPIC",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: "KTP PIC",
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: false,
  },
  {
    name: "fileBankNumber",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: t(strings.textBankNumber),
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: false,
  },
  {
    name: "fileNPWP",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: "NPWP",
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: false,
  },
  {
    name: "fileTDPSE",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: "TD PSE",
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: true,
  },
  {
    name: "fileDeedEstablishment",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: t(strings.textDeedEstablishmentAndAmendment),
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: false,
  },
  {
    name: "fileSKKemenkumham",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: "SK Kemenkumham",
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: false,
  },
  {
    name: "fileNIB",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: "NIB",
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: isCorporate ? false : true,
  },
  {
    name: "fileInstitutionPermit",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: t(strings.textInstitutionPermit),
    type: "file",
    extensionFile: ".pdf",
    notes: "",
    isOptional: false,
  },
];

export default objectSupportingDoc;
