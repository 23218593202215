import { IconButton } from "@mui/material";
import classes from "../Style/general.module.css";
import CloseIcon from "../Icon/CloseIcon";
import CloseBlueIcon from "../Icon/CloseBlueIcon";

const CloseIconAction = ({ onClose, variant = "gray" }) => {
  return (
    <IconButton onClick={onClose} className={classes.divClose}>
      {variant === "blue" && <CloseBlueIcon />}
      {variant === "gray" && <CloseIcon />}
    </IconButton>
  );
};

export default CloseIconAction;
