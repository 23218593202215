import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getProgressData = (setProgressData) => {
  const headers = headerRequest();
  RequestHttpGateway.get(`/merchant/completion`, { headers })
    .then((res) => {
      const results = res.data.data;
      setProgressData({
        section1: results.registrationCompletion,
        section2: results.personInChargeCompletion,
        section3: results.businessCompletion,
        section4: results.serviceFeeCompletion,
        step2: results.supportingDocumentCompletion,
      });
    })
    .catch((err) => {});
};

export default getProgressData;
