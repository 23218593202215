const RightIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 20}
    height={heightIcon || 20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.1922 10.4423L7.94217 16.6923C7.8841 16.7504 7.81516 16.7964 7.73929 16.8278C7.66342 16.8593 7.5821 16.8755 7.49998 16.8755C7.41786 16.8755 7.33654 16.8593 7.26067 16.8278C7.1848 16.7964 7.11586 16.7504 7.05779 16.6923C6.99972 16.6342 6.95366 16.5653 6.92224 16.4894C6.89081 16.4135 6.87463 16.3322 6.87463 16.2501C6.87463 16.168 6.89081 16.0867 6.92224 16.0108C6.95366 15.9349 6.99972 15.866 7.05779 15.8079L12.8664 10.0001L7.05779 4.19229C6.94052 4.07502 6.87463 3.91596 6.87463 3.7501C6.87463 3.58425 6.94052 3.42519 7.05779 3.30792C7.17507 3.19064 7.33413 3.12476 7.49998 3.12476C7.66583 3.12476 7.82489 3.19064 7.94217 3.30792L14.1922 9.55792C14.2503 9.61596 14.2964 9.68489 14.3278 9.76077C14.3593 9.83664 14.3755 9.91797 14.3755 10.0001C14.3755 10.0822 14.3593 10.1636 14.3278 10.2394C14.2964 10.3153 14.2503 10.3842 14.1922 10.4423Z"
      fill="#151313"
    />
  </svg>
);

export default RightIcon;
