import strings from "../Constant/Locales/Strings/GeneralStrings";
import { useTranslation } from "react-i18next";
import PageWithIllustration from "../Component/PageWithIllustration";

const ExpiredPage = () => {
  const { t } = useTranslation("General");
  const illustration =
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727841534/28_1_ymrl0u.png";

  return (
    <PageWithIllustration
      illustration={illustration}
      textTitle={t(strings.textPageExpired)}
      textSubTitle={t(strings.textDescPageExpired)}
      textErrorCode=""
      buttonAction={null}
    />
  );
};

export default ExpiredPage;
