const MailIcon = ({ widthIcon, heightIcon }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="envelope-fill">
      <path
        id="Vector"
        d="M18 3.75H3C2.83424 3.75 2.67527 3.81585 2.55806 3.93306C2.44085 4.05027 2.375 4.20924 2.375 4.375V15C2.375 15.3315 2.5067 15.6495 2.74112 15.8839C2.97554 16.1183 3.29348 16.25 3.625 16.25H17.375C17.7065 16.25 18.0245 16.1183 18.2589 15.8839C18.4933 15.6495 18.625 15.3315 18.625 15V4.375C18.625 4.20924 18.5592 4.05027 18.4419 3.93306C18.3247 3.81585 18.1658 3.75 18 3.75ZM8.21172 10L3.625 14.2039V5.79609L8.21172 10ZM9.13672 10.8477L10.0742 11.7109C10.1895 11.8168 10.3404 11.8755 10.4969 11.8755C10.6534 11.8755 10.8042 11.8168 10.9195 11.7109L11.857 10.8477L16.3883 15H4.60703L9.13672 10.8477ZM12.7883 10L17.375 5.79531V14.2047L12.7883 10Z"
        fill="#151313"
      />
    </g>
  </svg>
);
export default MailIcon;
