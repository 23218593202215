const objectsRegistAccount = (t, strings) => [
  {
    name: "businessName",
    placeholder: t(strings.textPlaceholderBusinessName),
    label: t(strings.textBusinessName),
    type: "text",
  },
  {
    name: "fullName",
    placeholder: t(strings.textPlaceholderFullName),
    label: t(strings.textFullname),
    type: "text",
  },
  {
    name: "email",
    placeholder: t(strings.textPlaceholderEmail),
    label: t(strings.textEmail),
    type: "text",
  },
  {
    name: "phoneNumber",
    placeholder: t(strings.textPlaceholderPhoneNumber),
    label: t(strings.textPhoneNumber),
    type: "text",
  },
  {
    name: "businessType",
    placeholder: "",
    label: t(strings.textBusinessType),
    type: "text",
  },
  {
    name: "password",
    placeholder: t(strings.textPlaceholderPassword),
    label: t(strings.textPassword),
    type: "password",
  },
  {
    name: "address",
    placeholder: t(strings.textPlaceholderAddress),
    label: t(strings.textAddress),
    type: "text",
    isOptional: true,
    isValidate: false,
  },
];

export default objectsRegistAccount;
