import encryptData from "./encryptData";

const headerRequest = (passedToken) => {
  const token = localStorage.getItem("token") || "";
  const exactToken = passedToken || token;
  const XReqOnboarding = encryptData();
  const timestamp = Math.floor(Date.now() / 1000); // Using Date.now() for simplicity

  return {
    "X-Req-Onboarding": XReqOnboarding,
    timestamp,
    Authorization: exactToken,
  };
};

export default headerRequest;
