export const listPayment = [
  {
    merchantChannelType: "Credit Card",
    channelName: "Visa Master Card",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727837819/digital-product-logo/Payment%20Logo/Visa-Mastercard_oyhbk4.jpg",
  },
  {
    merchantChannelType: "Virtual Account",
    channelName: "CIMB NIAGA",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_22_f6xciy.png",
  },
  {
    merchantChannelType: "Virtual Account",
    channelName: "Permata Bank",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838334/digital-product-logo/Payment%20Logo/Bank_Permata_-koleksilogo.com_kx7jbg.png",
  },
  {
    merchantChannelType: "Virtual Account",
    channelName: "Bank BNI",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838334/digital-product-logo/Payment%20Logo/BNI_logo.svg_eq8dph.png",
  },
  {
    merchantChannelType: "Virtual Account",
    channelName: "Bank BCA",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_9_osicbm.png",
  },
  {
    merchantChannelType: "Virtual Account",
    channelName: "Bank BRI",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838355/digital-product-logo/Payment%20Logo/image_31_ygvqkp.png",
  },
  {
    merchantChannelType: "Virtual Account",
    channelName: "Bank Mandiri",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_10_gjudvg.png",
  },
  {
    merchantChannelType: "Virtual Account",
    channelName: "Bank Neo Commerce",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_19_mbsllj.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "OVO",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_13_z5gkr7.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "Shopee Pay",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_14-1_piakqm.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "Link Aja!",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_23_id2f8l.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "Dana",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_24_j0ssji.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "Alipay",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_26_oopgia.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "Virgo",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_28_eptha3.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "Wechat",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838355/digital-product-logo/Payment%20Logo/image_25_pomjau.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "QRIS",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_20_emekfg.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "QRIS Nobu",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_27_bewcn6.png",
  },
  {
    merchantChannelType: "Wallet",
    channelName: "QRIS SHOPEE PAY",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_14_y5vlfo.png",
  },
  {
    merchantChannelType: "Over The Counter (OTC)",
    channelName: "Finpay",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_16_teewse.png",
  },
  {
    merchantChannelType: "Over The Counter (OTC)",
    channelName: "Alfamart by Finpay",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_29_md9i5n.png",
  },
  {
    merchantChannelType: "Over The Counter (OTC)",
    channelName: "Pegadaian by Finpay",
    logo: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_30_tksaha.png",
  },
];
