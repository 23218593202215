import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const onSubmitMerchantInfo = (
  t,
  strings,
  payload,
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  setFormData,
  showSnackbar
) => {
  setFormData((prev) => ({ ...prev, loadingSubmit: true }));
  const headers = headerRequest();
  RequestHttpGateway.post(`merchant/step-one`, payload, { headers })
    .then((res) => {
      showSnackbar(res.data.response_message, "success");
      if (activeStepOutlined === 0 && activeSectionNumber === 3) {
        setActiveStepOutlined((prev) => prev + 1);
        setActiveSectionNumber(0);
      } else {
        setActiveSectionNumber((prev) => prev + 1);
      }
    })
    .catch((err) => {
      const message =
        err.response?.data?.response_message ||
        t(strings.textServiceNotAvailable);
      showSnackbar(message, "error");
    })
    .finally(() => {
      setFormData((prev) => ({ ...prev, loadingSubmit: false }));
    });
};

export default onSubmitMerchantInfo;
