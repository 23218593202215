import { useTranslation } from "react-i18next";
import Buttons from "../../General/Component/Buttons";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import LeftArrow from "../../General/Icon/LeftArrow";
import RightIcon from "../../General/Icon/RightIcon";
import classes from "../RegistMerchant.module.css";
import ContactSection from "../../General/Component/ContactSection";
import { CardInformation } from "../../General/Component/CardInformation";
import { Box } from "@mui/material";

const VerificationProcess = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  const { t } = useTranslation("RegistMerchant");

  const handleDisabledButton = () => {
    return false;
  };

  const handlePreviousSection = () => {
    setActiveStepOutlined((prev) => prev - 1);
    setActiveSectionNumber(0);
  };

  const onSubmitVerificationProcess = () => {
    setActiveStepOutlined((prev) => prev + 1);
    setActiveSectionNumber(0);
  };

  return (
    <>
      <div className={classes.divFormStepper}>
        <CardInformation
          header={t(strings.textDataVerificationInProgress)}
          text={t(strings.textDataVerificationInProgressSubtitle)}
          status={"waiting"}
        />
        <div className={classes.divButtonVerif}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textCheckVerificationProcess)}
            handleDisabledButton={() => {
              return false;
            }}
            onSubmitData={onSubmitVerificationProcess}
          />
        </div>

        {/* Buttons ini hanya untuk testing. Aslinya ga ada */}
        <div className={classes.divGroupButton}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textPrevious)}
            handleDisabledButton={() => false}
            onSubmitData={handlePreviousSection}
            leftIcon={<LeftArrow />}
            isSolid={false}
          />
          <Buttons
            loadingSubmit={false}
            label={t(strings.textNext)}
            handleDisabledButton={handleDisabledButton}
            onSubmitData={onSubmitVerificationProcess}
            rightIcon={<RightIcon />}
          />
        </div>

        <ContactSection
          t={t}
          title={t(strings.textHaveAQuestion)}
          subtitle={t(strings.textContactViaWaandEmail)}
        />
      </div>
    </>
  );
};

export default VerificationProcess;
