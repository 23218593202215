const listIndustry = (t, strings) => [
  { id: "Jasa Akuntan", label: t(strings.textAccountantServices) },
  { id: "Perbankan & Keuangan", label: t(strings.textBankingAndFinances) },
  { id: "Admin & HRD", label: t(strings.textAdminAndHRD) },
  {
    id: "Perawatan Kecantikan/Kesehatan",
    label: t(strings.textBeautyHealthCare),
  },
  {
    id: "Kontruksi Bangunan/Mechanical",
    label: t(strings.textBuildingConstructionOrMechanical),
  },
  { id: "Desain", label: t(strings.textDesign) },
  { id: "Pendidikan", label: t(strings.textEducation) },
  { id: "Jasa Keamanan", label: t(strings.textSecurityServices) },
  { id: "Jasa Kebersihan", label: t(strings.textCleaningServices) },
  {
    id: "Jasa Perhotelan/Pariwisata",
    label: t(strings.textHospitalityOrTourismServices),
  },
  { id: "Makanan/Minuman", label: t(strings.textFoodOrBeverage) },
  { id: "Teknologi Informasi", label: t(strings.textInformationTechnology) },
  { id: "Asuransi", label: t(strings.textInsurance) },
  { id: "Manufaktur", label: t(strings.textManufacturing) },
  { id: "Pemasaran", label: t(strings.textMarketing) },
  { id: "Media/Periklanan", label: t(strings.textMediaOrAdvertising) },
  {
    id: "Medis/Pelayanan Kesehatan",
    label: t(strings.textMedicalOrHealthcare),
  },
  { id: "Telekomunikasi", label: t(strings.textTelecommunications) },
  {
    id: "Transportasi & Logistik",
    label: t(strings.textTransportAndLogistics),
  },
  { id: "Pialang", label: t(strings.textBroker) },
  { id: "Distributor", label: t(strings.textDistributor) },
  { id: "Kosultan", label: t(strings.textConsultant) },
  { id: "Lainnya", label: t(strings.textOthers) },
];

export default listIndustry;
