import { Box, Grid, Typography } from "@mui/material";
import FormWithIllustration from "../../General/Component/FormWithIllustration";
import classes from "../RegistMerchant.module.css";
import { useTranslation } from "react-i18next";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import OutlinedCheckIcon from "../../General/Icon/OutlinedCheckIcon";
import { useNavigate } from "react-router-dom";
import * as routeEnum from "../../General/Constant/routes";
import documentList from "../Constant/documentList";
import { useSelector } from "react-redux";
import Buttons from "../../General/Component/Buttons";

const ChooseDocument = () => {
  const { t } = useTranslation("RegistMerchant");
  const navigate = useNavigate();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);

  const renderDocuments = (isCorporate) => {
    return documentList(t, strings)
      .filter((doc) => doc.corporate === isCorporate)
      .map((data) => (
        <div key={data.id} className={classes.divRowList}>
          <div className={classes.divRowList}>
            <OutlinedCheckIcon />
          </div>
          <Typography className={classes.textBlackSemiBold16}>
            {data.label}
            {data.id === "TD PSE" && isCorporate && (
              <span
                className={classes.textGrayItalic14}
                style={{ marginLeft: 4 }}
              >
                *{t(strings.textOptional)}
              </span>
            )}
          </Typography>
        </div>
      ));
  };

  const goToMerchantForm = () => {
    navigate(routeEnum.REGISTRATION_FORM);
  };

  const contentChooseDoc = (
    <div className={classes.divFormContainer}>
      <div className={classes.divBoxForm}>
        <Typography className={classes.textBold20}>
          {t(strings.textRegisterPreparation)}
        </Typography>

        <Box
          component="form"
          noValidate
          autoComplete="off"
          className={classes.boxForm}
        >
          {userDataLogin?.business_type === "Corporate" &&
            renderDocuments(true)}
          {userDataLogin?.business_type === "Non Corporate" &&
            renderDocuments(false)}
        </Box>
      </div>
      <div className={classes.divButtonLow}>
        <Buttons
          loadingSubmit={false}
          label={t(strings.textContinueProcess)}
          handleDisabledButton={() => {
            return false;
          }}
          onSubmitData={goToMerchantForm}
        />
      </div>
    </div>
  );

  return (
    <Grid>
      <FormWithIllustration content={contentChooseDoc} />
    </Grid>
  );
};

export default ChooseDocument;
