import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import classes from "../RegistMerchant.module.css";
import ExpandMoreIcon from "../../General/Icon/ExpandMoreIcon";
import string from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import { listPayment } from "../../General/Helper/listPayment";
import { useTranslation } from "react-i18next";
import Buttons from "../../General/Component/Buttons";
import LeftArrowIcon from "../../General/Icon/LeftArrowIcon";
import RightIcon from "../../General/Icon/RightIcon";
import React, { useEffect, useState } from "react";
import getMerchantPreview from "../Helper/getMerchantPreview";
import DialogPopUp from "../../General/Component/DialogPopUp";
import { LoadingState } from "../../General/Component/LoadingComp";
import FormCheckBox from "../../General/Component/FormCheckBox";
import DocumentIcon from "../../General/Icon/DocumentIcon";
import InfoComponent from "../../General/Component/InfoComponent";
import onSubmitMerchant from "../Helper/onSubmitMerchant";
import useSnackbar from "../../General/Helper/useSnackbar";
import SnackbarNotification from "../../General/Component/SnackbarNotification";

const AccordionStyle = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ _theme }) => ({
  "& .MuiAccordion-root": {
    backgroundColor: "#fff",
    position: "none",
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#F6F4F5",
  },
  "& .MuiAccordionDetails-root": {
    padding: "10px 0 10px 0",
  },
}));

const AccordionPreview = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const showPreviewData = (item) => {
    return item?.details?.map((el, index) => (
      <div key={index} className={classes.section_preview}>
        {el.subtitle && (
          <Typography className={classes.subtitle_detail_preview}>
            {el.subtitle}
          </Typography>
        )}
        {el.subtext && (
          <Typography className={classes.subtext_detail_preview}>
            {el.subtext}
          </Typography>
        )}
        <div className={classes.section_value}>
          {el.list?.map((e, eindex) => (
            <React.Fragment key={eindex}>
              {e.type === "img" ? (
                <div className={classes.div_pannel_4}>
                  <div className={classes.div_img_value}>
                    {listPayment.map(
                      (val, valIndex) =>
                        val.channelName === e.value && (
                          <img
                            key={valIndex}
                            src={val.logo}
                            alt="logo"
                            width="50px"
                          />
                        )
                    )}
                    <Typography className={classes.value_payment}>
                      {e.value || "-"}
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className={classes.div_label_value}>
                  <div className={classes.div_label_opti}>
                    {e.label && (
                      <Typography className={classes.label_preview}>
                        {e.label}
                      </Typography>
                    )}
                    {!e.mandatory && (
                      <Typography className={classes.text_opti_label}>
                        {t(string.textOptional)}
                      </Typography>
                    )}
                  </div>

                  {e.type === "file" ? (
                    <div className={classes.value_file_field}>
                      <DocumentIcon />
                      <Typography className={classes.value_file_text}>
                        {e.value || "-"}
                      </Typography>
                    </div>
                  ) : (
                    <TextField
                      disabled
                      fullWidth
                      defaultValue={e.value || "-"}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-disabled": {
                          "& fieldset": {
                            borderRadius: "8px",
                            border: "1px solid #CFDFEC",
                          },
                        },
                        "& .MuiOutlinedInput-input.Mui-disabled": {
                          borderRadius: "8px",
                          height: "10px",
                          WebkitTextFillColor: "#231F20",
                          background: "#F6F4F5",
                        },
                      }}
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    ));
  };

  const handleCheck = (check) => {
    setChecked(check);
  };

  const handleDisabledButton = () => {
    return !checked;
  };

  useEffect(() => {
    getMerchantPreview(t, string, setData, setLoading);
  }, [t]);

  const handlePreviousSection = () => {
    setActiveSectionNumber((prev) => prev - 1);
  };

  const onSubmitPreview = () => {
    setOpenConfirm(true);
  };

  return !loading ? (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
      <DialogPopUp
        open={openConfirm}
        maxWidth={"sm"}
        onClose={() => setOpenConfirm(false)}
        title={t(string.textWantContinueProcess)}
        content={
          <>
            <Typography className={classes.content_confirm}>
              {t(string.textMakeSureCorrect)}
            </Typography>
            <div className={classes.divGroupButton}>
              <Buttons
                loadingSubmit={false}
                label={t(string.textNoCheckAgain)}
                handleDisabledButton={() => false}
                onSubmitData={() => setOpenConfirm(false)}
                leftIcon={null}
                isSolid={false}
              />
              <Buttons
                loadingSubmit={loadingSubmit}
                label={t(string.textYesContinue)}
                handleDisabledButton={() => false}
                onSubmitData={() =>
                  onSubmitMerchant(
                    setActiveStepOutlined,
                    setActiveSectionNumber,
                    setLoadingSubmit,
                    showSnackbar,
                    t,
                    string
                  )
                }
                rightIcon={null}
              />
            </div>
          </>
        }
        actions={null}
      />
      <div className={classes.divFormStepper}>
        <Typography className={classes.textPreviewData}>
          {t(string.textPreviewData)}
        </Typography>

        <InfoComponent
          severity={"info"}
          message={t(string.textPleaseDoubleCheck)}
        />

        <div>
          {data?.map((item, index) => (
            <AccordionStyle key={index} defaultExpanded={item.expanded}>
              <AccordionSummary
                className={classes.Accor}
                expandIcon={<ExpandMoreIcon />}
                id={item.pannel}
              >
                <Typography className={classes.summary_text}>
                  {item.summary}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {showPreviewData(item)}
                <div className={classes.line_bottom} />
              </AccordionDetails>
            </AccordionStyle>
          ))}
          <div className={classes.div_check_confirm}>
            <FormCheckBox
              label={t(string.textLabelConfirmationTnc)}
              checked={checked}
              onChange={handleCheck}
              columnsPerRow={1}
              name={"agreeTNC"}
            />
          </div>

          <div className={classes.divGroupButton}>
            <Buttons
              loadingSubmit={false}
              label={t(string.textPrevious)}
              handleDisabledButton={() => false}
              onSubmitData={handlePreviousSection}
              leftIcon={<LeftArrowIcon />}
              isSolid={false}
            />
            <Buttons
              loadingSubmit={false}
              label={t(string.textNext)}
              handleDisabledButton={handleDisabledButton}
              onSubmitData={onSubmitPreview}
              rightIcon={<RightIcon />}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default AccordionPreview;
