import { useTranslation } from "react-i18next";
import useSnackbar from "../../General/Helper/useSnackbar";
import { useSelector } from "react-redux";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import { Typography } from "@mui/material";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import InfoComponent from "../../General/Component/InfoComponent";
import objectSupportingDoc from "../Constant/objectSupportingDoc";
import convertFileToBase64 from "../../General/Helper/convertFileToBase64";
import { useEffect, useState } from "react";
import { renderSupportingDoc } from "../Helper/renderSupportingDocs";
import Buttons from "../../General/Component/Buttons";
import LeftArrow from "../../General/Icon/LeftArrow";
import RightIcon from "../../General/Icon/RightIcon";
import getMerchantDetail from "../Helper/getMerchantDetail";
import onSubmitSupportDoc from "../Helper/onSubmitSupportDoc";
import getMerchantFile from "../Helper/getMerchantFile";
import { documentConstant } from "../Constant/documentConstant";
import { LoadingState } from "../../General/Component/LoadingComp";

const FormSupportingDocument = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [merchantDetail, setMerchantDetail] = useState(null);
  const [loadingMerchant, setLoadingMerchant] = useState(true);
  const [isWNA, setIsWNA] = useState(false);
  const [formData, setFormData] = useState({
    fileDirectorKTP: { file: "", fileName: "" },
    filePassportDirector: { file: "", fileName: "" },
    fileKTPPIC: { file: "", fileName: "" },
    fileBankNumber: { file: "", fileName: "" },
    fileNPWP: { file: "", fileName: "" },
    fileTDPSE: { file: "", fileName: "" },
    fileDeedEstablishment: { file: "", fileName: "" },
    fileSKKemenkumham: { file: "", fileName: "" },
    fileNIB: { file: "", fileName: "" },
    fileInstitutionPermit: { file: "", fileName: "" },
    fileKTPPerson: { file: "", fileName: "" },
    loadingSubmit: false,
  });
  const isCorporate =
    userDataLogin?.business_type === "Corporate" ? true : false;

  useEffect(() => {
    handleDisabledButton();
    calculateProgressValue();
  }, [formData]);

  useEffect(() => {
    getMerchantDetail(
      t,
      strings,
      activeSectionNumber,
      activeStepOutlined,
      setMerchantDetail,
      setLoadingMerchant,
      showSnackbar
    );
  }, [activeSectionNumber, activeStepOutlined, showSnackbar, t]);

  useEffect(() => {
    const citizenship = merchantDetail?.directorCitizenship;
    if (citizenship) {
      if (citizenship === "Indonesian") {
        setIsWNA(false);
      } else {
        setIsWNA(true);
      }
    } else {
      setIsWNA(false);
    }
  }, [merchantDetail]);

  useEffect(() => {
    if (merchantDetail) {
      const docsMerchant = merchantDetail?.merchantDocuments;
      documentConstant(isCorporate, isWNA).map((data) => {
        const checkObject = docsMerchant.filter(
          (docsMerchant) => docsMerchant.documentType.code === data.code
        );
        const results = { ...data, ...checkObject[0] };

        if (results.documentUrl) {
          getMerchantFile(
            t,
            strings,
            results.name,
            "pdf",
            results.documentUrl,
            results.fileName,
            setFormData,
            showSnackbar
          );
        }
      });
    }
  }, [merchantDetail, isCorporate, isWNA]);

  const calculateProgressValue = () => {
    const {
      fileDirectorKTP,
      fileKTPPIC,
      filePassportDirector,
      fileTDPSE,
      fileDeedEstablishment,
      fileSKKemenkumham,
      fileInstitutionPermit,
      fileNIB,
      fileBankNumber,
      fileNPWP,
      fileKTPPerson,
    } = formData;

    let progressConditions = [fileBankNumber.file, fileNPWP.file, fileNIB.file];

    if (isCorporate) {
      progressConditions = progressConditions.concat(
        fileTDPSE.file,
        fileDeedEstablishment.file,
        fileSKKemenkumham.file,
        fileInstitutionPermit.file
      );

      if (isWNA) {
        progressConditions = progressConditions.concat(
          filePassportDirector.file,
          fileKTPPIC.file
        );
      } else {
        progressConditions.push(fileDirectorKTP.file);
      }
    } else {
      progressConditions.push(fileKTPPerson.file);
    }

    const validProgressCount = progressConditions.filter(Boolean).length;
    const progressNumber =
      (validProgressCount / progressConditions.length) * 20;

    setProgressData((prev) => ({
      ...prev,
      step2: Math.ceil(progressNumber),
    }));
  };

  const handleChange = async (e) => {
    const { name, type, value } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      if (file) {
        try {
          const base64File = await convertFileToBase64(file);
          setFormData((prev) => ({
            ...prev,
            [name]: { file: base64File, fileName: file.name },
          }));
        } catch (error) {}
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCloseFile = (e, name, label) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById(label).value = "";

    setFormData((prev) => ({
      ...prev,
      [name]: {
        file: "",
        fileName: "",
      },
    }));
  };

  const handlePreviousSection = () => {
    setActiveStepOutlined((prev) => prev - 1);
    setActiveSectionNumber(3);
  };

  const handleDisabledButton = () => {
    const isFormIncomplete =
      formData.loadingSubmit ||
      !formData.fileBankNumber.file ||
      !formData.fileNPWP.file;

    const isCorporateField =
      !formData.fileDeedEstablishment.file ||
      !formData.fileSKKemenkumham.file ||
      !formData.fileInstitutionPermit.file ||
      !formData.fileNIB.file;

    const isWNAField =
      !formData.filePassportDirector.file || !formData.fileKTPPIC.file;

    const isWNIField = !formData.fileDirectorKTP.file;

    const isNonCorporateField = !formData.fileKTPPerson.file;

    if (isCorporate) {
      if (isWNA) {
        return isFormIncomplete || isCorporateField || isWNAField;
      } else if (!isWNA) {
        return isFormIncomplete || isCorporateField || isWNIField;
      }
    } else {
      return isFormIncomplete || isNonCorporateField;
    }
  };

  const onSubmitSupportingDoc = () => {
    const {
      fileDirectorKTP,
      fileKTPPIC,
      filePassportDirector,
      fileTDPSE,
      fileDeedEstablishment,
      fileSKKemenkumham,
      fileInstitutionPermit,
      fileNIB,
      fileBankNumber,
      fileNPWP,
      fileKTPPerson,
    } = formData;

    const payload = {
      directorId: isCorporate
        ? isWNA
          ? { ...filePassportDirector }
          : { ...fileDirectorKTP }
        : { ...fileKTPPerson },

      accountNumber: {
        ...fileBankNumber,
      },
      npwp: {
        ...fileNPWP,
      },
      supportingDocumentCompletion: progressData.step2,
      totalCompletion: 20,
    };
    if (isCorporate) {
      payload["tdpse"] = { ...fileTDPSE };
      payload["amendmentDeed"] = { ...fileDeedEstablishment };
      payload["ministryPermit"] = { ...fileSKKemenkumham };
      payload["companyPermit"] = { ...fileInstitutionPermit };
      payload["nib"] = { ...fileNIB };

      if (isWNA) {
        payload["picId"] = {
          ...fileKTPPIC,
        };
      }
    } else {
      if (fileNIB.file) {
        payload["nib"] = { ...fileNIB };
      }
    }

    setFormData((prev) => ({ ...prev, loadingSubmit: true }));
    onSubmitSupportDoc(
      t,
      strings,
      payload,
      setActiveSectionNumber,
      setFormData,
      showSnackbar
    );
  };

  return !loadingMerchant ? (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <div className={classes.divFormStepper}>
        <div className={classes.groupWithSubtileSmall}>
          <Typography className={classes.textSuperBold18}>
            {t(strings.textSupportingDocuments)}
          </Typography>
          <Typography className={classes.textRegularDarkGray14}>
            {t(strings.textSubtitleSupportingDocs)}
          </Typography>
        </div>

        <div className={classes.divLine} />

        <div className={classes.groupWithSubtile}>
          <InfoComponent
            severity={"info"}
            message={t(strings.textInfoSupportingDoc)}
          />
          <div className={classes.divWrapFourColumn}>
            {objectSupportingDoc(t, strings, isCorporate)
              .reduce((rows, field, index) => {
                if (index % 2 === 0) {
                  rows.push([field]);
                } else {
                  rows[rows.length - 1].push(field);
                }
                return rows;
              }, [])
              .map((row) => {
                return row.map((field, index) => {
                  return renderSupportingDoc(
                    classes,
                    isCorporate,
                    isWNA,
                    field,
                    index,
                    formData,
                    handleChange,
                    showSnackbar,
                    handleCloseFile
                  );
                });
              })}
          </div>

          <div className={classes.divGroupButton}>
            <Buttons
              loadingSubmit={false}
              label={t(strings.textPrevious)}
              handleDisabledButton={() => false}
              onSubmitData={handlePreviousSection}
              leftIcon={<LeftArrow />}
              isSolid={false}
            />
            <Buttons
              loadingSubmit={formData.loadingSubmit}
              label={t(strings.textNext)}
              handleDisabledButton={handleDisabledButton}
              onSubmitData={onSubmitSupportingDoc}
              rightIcon={<RightIcon />}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default FormSupportingDocument;
