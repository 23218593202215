import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const onSubmitSupportDoc = (
  t,
  strings,
  payload,
  setActiveSectionNumber,
  setFormData,
  showSnackbar
) => {
  const headers = headerRequest();
  RequestHttpGateway.post(`merchant/step-two`, payload, { headers })
    .then((res) => {
      showSnackbar(res.data.response_message, "success");
      setActiveSectionNumber((prev) => prev + 1);
    })
    .catch((err) => {
      const message =
        err.response?.data?.response_message ||
        t(strings.textServiceNotAvailable);
      showSnackbar(message, "error");
    })
    .finally(() => {
      setFormData((prev) => ({ ...prev, loadingSubmit: false }));
    });
};

export default onSubmitSupportDoc;
