import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getCredentialStaging = (
  setLoading,
  setIsStaging,
  setDataStaging,
  t,
  strings,
  showSnackbar
) => {
  const headers = headerRequest();
  setLoading(true);
  RequestHttpGateway.get(`/mis/credential/staging`, { headers })
    .then((res) => {
      const response = res.data.data;

      const data = Object.entries(response).reduce((acc, [key, value]) => {
        let label = "";

        switch (key) {
          case "merchantIndex":
            label = t(strings.textMerchantIndex);
            break;
          case "merchantPicName":
            label = t(strings.textEmployeeName);
            break;
          case "secretUnboundId":
            label = "Merchant Secret Unbound";
            break;
          case "hashkey":
            label = "Merchant Hash Key";
            break;
          case "linkWeb":
            label = t(strings.textLinkWebStaging);
            break;
          case "linkDocs":
            label = t(strings.textDocumentations);
            break;
          default:
            return acc; // Skip unknown properties
        }

        acc.push({ label, value });
        return acc;
      }, []);

      setDataStaging(data);
      setLoading(false);
      setIsStaging(true);
    })
    .catch((err) => {
      setLoading(false);
      showSnackbar(
        err.response?.data?.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    });
};

export default getCredentialStaging;
