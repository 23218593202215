const listCategory = (t, strings) => [
  { id: "API Integration", label: t(strings.textAPIIntegration) },
  { id: "Non Integration", label: t(strings.textNonIntegration) },
  {
    id: "Disbursement Only",
    label: t(strings.textDisbursementOnly),
  },
];

export default listCategory;
