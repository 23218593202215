const MessageIcon = ({ widthIcon, heightIcon }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="chats-circle-fill">
      <path
        id="Vector"
        d="M18.1299 14.5905C18.5617 13.6948 18.7736 12.709 18.748 11.7149C18.7223 10.7208 18.4598 9.74725 17.9823 8.87501C17.5049 8.00276 16.8261 7.25704 16.0026 6.69978C15.179 6.14253 14.2343 5.78982 13.2471 5.67097C12.9186 4.9072 12.441 4.21664 11.8423 3.63974C11.2436 3.06284 10.5358 2.6112 9.76032 2.31126C8.98488 2.01131 8.15739 1.8691 7.32631 1.89295C6.49523 1.9168 5.67725 2.10623 4.92029 2.45015C4.16333 2.79407 3.4826 3.28556 2.91796 3.89584C2.35332 4.50612 1.91611 5.22293 1.63195 6.00429C1.34779 6.78565 1.22239 7.61585 1.26308 8.44628C1.30378 9.27671 1.50975 10.0907 1.86894 10.8405L1.30097 12.771C1.23747 12.9865 1.23322 13.2151 1.28868 13.4328C1.34414 13.6506 1.45725 13.8493 1.61612 14.0082C1.77498 14.167 1.97373 14.2801 2.19145 14.3356C2.40917 14.3911 2.63781 14.3868 2.85332 14.3233L4.78378 13.7553C5.40365 14.0532 6.06823 14.2472 6.75097 14.3296C7.08267 15.1068 7.56853 15.8086 8.17918 16.3927C8.78982 16.9768 9.51257 17.431 10.3037 17.7279C11.0949 18.0247 11.938 18.158 12.7822 18.1198C13.6263 18.0815 14.454 17.8725 15.215 17.5053L17.1455 18.0733C17.3609 18.1367 17.5895 18.141 17.8071 18.0855C18.0247 18.0301 18.2234 17.9171 18.3822 17.7583C18.5411 17.5996 18.6542 17.4009 18.7097 17.1834C18.7653 16.9658 18.7612 16.7372 18.6978 16.5218L18.1299 14.5905ZM16.8603 14.7053L17.4994 16.8749L15.3307 16.2366C15.174 16.1912 15.0057 16.2089 14.8619 16.2858C13.711 16.9004 12.3656 17.0411 11.1124 16.678C9.85922 16.3149 8.79742 15.4767 8.15332 14.3421C9.00908 14.2527 9.83715 13.9875 10.5856 13.5631C11.334 13.1386 11.9867 12.5641 12.5026 11.8755C13.0185 11.1869 13.3865 10.3992 13.5836 9.56163C13.7807 8.72409 13.8026 7.85486 13.6478 7.00847C14.3935 7.18423 15.0887 7.52896 15.68 8.01605C16.2712 8.50315 16.7426 9.11959 17.0578 9.81779C17.373 10.516 17.5236 11.2773 17.4979 12.0429C17.4722 12.8085 17.2709 13.558 16.9096 14.2335C16.8318 14.3782 16.8141 14.5477 16.8603 14.7053Z"
        fill="#151313"
      />
    </g>
  </svg>
);
export default MessageIcon;
