import classes from "../RegistMerchant.module.css";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import { CardInformation } from "../../General/Component/CardInformation";
import { useTranslation } from "react-i18next";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "../../General/Icon/ExpandMoreIcon";
import { listPayment } from "../../General/Helper/listPayment";
import { useEffect, useState } from "react";
import Buttons from "../../General/Component/Buttons";
import ContactSection from "../../General/Component/ContactSection";
import VerificationFailed from "../../General/Component/VerificationFailed";
import RightIcon from "../../General/Icon/RightIcon";
import LeftArrow from "../../General/Icon/LeftArrow";

export function VerificationStatus({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) {
  /*
    note tahap development:
    1. validasi status successfully
    2. validasi status rejected
    */

  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const [channel, setChannel] = useState({
    cc: [],
    virtualAccount: [],
    eWallet: [],
    otc: [],
  });

  const dummyData = {
    success: true,
  };

  useEffect(() => {
    const cc = listPayment.filter(
      (el) => el.channelName === "Visa Master Card"
    );
    const virtualAccount = listPayment.filter(
      (el) =>
        el.channelName === "Bank BCA" ||
        el.channelName === "Bank BRI" ||
        el.channelName === "Bank Mandiri" ||
        el.channelName === "Bank BNI"
    );
    const eWallet = listPayment.filter(
      (el) =>
        el.channelName === "OVO" ||
        el.channelName === "Shopee Pay" ||
        el.channelName === "Link Aja!" ||
        el.channelName === "Dana" ||
        el.channelName === "QRIS SHOPEE PAY"
    );
    const otc = listPayment.filter(
      (el) =>
        el.channelName === "Finpay" || el.channelName === "Alfamart by Finpay"
    );

    setChannel({
      cc,
      virtualAccount,
      eWallet,
      otc,
    });
  }, []);

  const paymentList = (list) => {
    let merchantChannelType = list[0]?.merchantChannelType;
    if (merchantChannelType === "Wallet") {
      merchantChannelType = "e-Money/e-Wallet";
    }

    return (
      <Accordion
        disableGutters
        sx={{
          boxShadow: "none",
          borderBottom: "1px solid #CFDFEC",
        }}
      >
        <AccordionSummary sx={{ padding: "0" }} expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.summary_text}>
            {t(merchantChannelType)}
          </Typography>
        </AccordionSummary>

        <AccordionDetails
          sx={{ paddingLeft: "0", paddingRight: "0", paddingBottom: "0" }}
        >
          <Grid container spacing={2}>
            {[
              {
                label: strings.payment,
                textAlign: "left",
              },
              { label: strings.fees, textAlign: "right" },
            ].map((el) => (
              <Grid item xs={6} sx={{ textAlign: el.textAlign }}>
                <Typography className={classes.textRegularDarkGray14}>
                  {t(el.label)}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>

        {list.map((el, index) => {
          const accordionDetailsStyle = {
            paddingLeft: "0",
            paddingRight: "0",
            marginBottom: "16px",
          };
          if (index !== list?.length - 1) {
            accordionDetailsStyle["paddingBottom"] = "0";
          }

          return (
            <AccordionDetails sx={accordionDetailsStyle}>
              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ textAlign: "left" }}>
                  <div className={classes.div_img_value}>
                    <img src={el.logo} alt="logo" width="50px" />
                    <Typography className={classes.value_payment}>
                      {el.channelName}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <Typography className={classes.subtext_detail_preview}>
                    Rp.0
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          );
        })}
      </Accordion>
    );
  };

  // nanti hapus aja ya
  const onSubmitVerif = () => {
    setActiveStepOutlined((prev) => prev + 1);
    setActiveSectionNumber(0);
  };

  const handlePreviousSection = () => {
    setActiveStepOutlined((prev) => prev - 1);
    setActiveSectionNumber(0);
  };

  return (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
      <div className={classes.divFormStepper}>
        {dummyData.success ? (
          <>
            <CardInformation
              header={t(strings.verificationSuccess)}
              text={t(strings.registrationSuccess)}
              status={"success"}
            />

            <div style={{ marginTop: "30px" }}>
              <Typography className={classes.textSuperBold18}>
                {t(strings.supportAndPaymentInfo)}
              </Typography>
              <Typography
                sx={{ marginTop: "8px" }}
                className={classes.textRegularDarkGray14}
              >
                {t(strings.paymentProviderDetails)}
              </Typography>
            </div>

            <div
              style={{
                width: "100%",
                marginTop: "10px",
              }}
            >
              {[
                paymentList(channel.cc),
                paymentList(channel.virtualAccount),
                paymentList(channel.eWallet),
                paymentList(channel.otc),
              ].map((el, i) => {
                const style = {
                  cursor: "pointer",
                };
                if (i > 0) {
                  style["marginTop"] = "16px";
                }
                return <div style={style}>{el}</div>;
              })}
            </div>
          </>
        ) : (
          <VerificationFailed t={t} />
        )}
        <ContactSection
          t={t}
          title={t(strings.textHaveAQuestion)}
          subtitle={t(strings.textContactViaWaandEmail)}
        />
        {dummyData.success ? (
          <Buttons
            loadingSubmit={false}
            label={t(strings.continue)}
            rightIcon={<RightIcon />}
            isSolid={true}
            handleDisabledButton={() => null}
            onSubmitData={onSubmitVerif}
          />
        ) : null}

        {/* nanti ganti aja ya. ini testing untuk steps controller aja */}
        {/* Buttons ini hanya untuk testing. Aslinya ga ada */}
        <div className={classes.divGroupButton}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textPrevious)}
            handleDisabledButton={() => false}
            onSubmitData={handlePreviousSection}
            leftIcon={<LeftArrow />}
            isSolid={false}
          />
          <Buttons
            loadingSubmit={false}
            label={t(strings.textNext)}
            handleDisabledButton={() => false}
            onSubmitData={onSubmitVerif}
            rightIcon={<RightIcon />}
          />
        </div>
      </div>
    </>
  );
}
