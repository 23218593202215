export const LOGIN = "/";
export const ACTIVATION_ACCOUNT = "/activation";
export const REGISTRATION = "/account_registration";
export const REGISTRATION_MERCHANT = "/merchant_registration";
export const REGISTRATION_FORM = "/merchant_registration_form";
export const FORGOT_PASSWORD = "/forgot_password";
export const RESET_PASSWORD = "/reset/password";
export const EXPIRED = "/expired";
export const NOT_FOUND = "/not_found";
export const ACCESS_DENIED = "/access_denied";
export const PREVIEW_DATA = "/preview_data";
