import React from "react";
import { FormHelperText, TextField, Typography } from "@mui/material";
import classes from "../Style/general.module.css";
import strings from "../Constant/Locales/Strings/GeneralStrings";
import { useTranslation } from "react-i18next";
import CloseIconAction from "./CloseIconAction";
import DocumentIcon from "../Icon/DocumentIcon";

const FileUpload = ({
  name,
  label,
  fileName,
  placeholder,
  icon,
  extensionFile,
  notes,
  isOptional,
  onChange,
  showSnackbar,
  handleClose,
}) => {
  const { t } = useTranslation("General");

  const handleButtonClick = (e) => {
    document.getElementById(label)?.click();
    e.preventDefault();
    e.stopPropagation();
    document.getElementById(label).value = "";
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const validExtensions = extensionFile.split(",").map((ext) => ext.trim());
    const maxSize = 10 * 1024 * 1024;

    if (file) {
      const fileExtension = file.name.split(".").pop();
      if (!validExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
        showSnackbar(
          `${t(strings.textFileTypeMustBe)}: ${extensionFile.toUpperCase()}`,
          "error"
        );
      } else if (file.size > maxSize) {
        showSnackbar(`${t(strings.textMaxFile5MB)}`, "error");
      } else {
        onChange(event);
      }
    }
  };

  return (
    <div className={classes.divFormField}>
      <Typography className={classes.textBold600BlackH4}>
        {label}
        {isOptional && (
          <span
            className={classes.textGrayItalic12}
            style={{ margin: "0 0 0 4px" }}
          >
            {t(strings.textOptional)}
          </span>
        )}
      </Typography>
      <TextField
        fullWidth
        value={fileName || ""}
        placeholder={placeholder}
        InputProps={{
          disableunderline: "true",
          readOnly: true,
          startAdornment: (
            <div
              style={{ padding: "0 8px 0 0" }}
              className={classes.cursorPointer}
            >
              {fileName ? <DocumentIcon /> : icon}
            </div>
          ),
          endAdornment: fileName ? (
            <div className={classes.divClose}>
              <CloseIconAction
                onClose={(e) => handleClose(e, name, label)}
                variant="blue"
              />
            </div>
          ) : null,
          classes: { input: classes.placeholderUpload },
        }}
        name={name || ""}
        htmlFor={label}
        onClick={handleButtonClick}
        sx={{
          background: fileName ? "#ECF5FF" : "#FFF",
          borderRadius: "8px",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "1px dashed #CFDFEC",
              display: "flex",
              padding: "14px 12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              fontSize: "14px",
            },
          },
          input: { cursor: "pointer" },
        }}
      />
      <input
        id={label}
        name={name || ""}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <FormHelperText>{notes || ""}</FormHelperText>
    </div>
  );
};

export default FileUpload;
