import { useTranslation } from "react-i18next";
import Textfields from "../../General/Component/Textfields";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import { useEffect, useState } from "react";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import Buttons from "../../General/Component/Buttons";
import RightIcon from "../../General/Icon/RightIcon";
import convertFileToBase64 from "../../General/Helper/convertFileToBase64";
import onSubmitMerchantInfo from "../Helper/onSubmitMerchantInfo";
import { useSelector } from "react-redux";
import getMerchantDetail from "../Helper/getMerchantDetail";
import getMerchantFile from "../Helper/getMerchantFile";
import { Typography } from "@mui/material";
import {
  renderBusinessOwnerField,
  renderCitizen,
  renderDirectorField,
  renderField,
} from "../Helper/renderMerchantInfo";
import objectMerchantWNA from "../Constant/objectMerchantWNA";
import objectMerchantWNI from "../Constant/objectMerchantWNI";
import {
  isValidAddress,
  isValidIDCard,
  isValidNPWP,
  isValidName,
  isValidNumber,
  isValidPassport,
  isValidURL,
} from "../../General/Helper/handleValidation";
import { LoadingState } from "../../General/Component/LoadingComp";
import getMROList from "../Helper/getMROList";
import InfoComponent from "../../General/Component/InfoComponent";

const FormRegistrationInfo = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
  errorVerificationData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [merchantDetail, setMerchantDetail] = useState(null);
  const [mroList, setMROList] = useState([]);
  const [loadingMerchant, setLoadingMerchant] = useState(true);
  const [loadingMRO, setLoadingMRO] = useState(false);
  const [isWNA, setIsWNA] = useState(false);
  const [isCorporate, setIsCorporate] = useState(
    userDataLogin?.business_type === "Corporate" ? true : false
  );
  const [formData, setFormData] = useState({
    merchantName: "",
    urlWeb: "",
    npwpNumberCompany: "",
    citizenship: "",
    ktpNumberDirector: "",
    passportDirector: "",
    address: "",
    businessTime: "",
    employeeNumber: "",
    referralName: "",
    directorName: "",
    guaratorName: "",
    idCardGuarantor: "",
    directorAddress: "",
    filePaymentFlow: { base64: "", fileName: "" },
    fileMerchantLogo: { base64: "", fileName: "" },
    loadingSubmit: false,
  });

  useEffect(() => {
    getMROList(t, strings, setMROList, setLoadingMRO, showSnackbar);
    getMerchantDetail(
      t,
      strings,
      activeSectionNumber,
      activeStepOutlined,
      setMerchantDetail,
      setLoadingMerchant,
      showSnackbar
    );
    if (userDataLogin?.business_type === "Corporate") {
      setIsCorporate(true);
    } else {
      setIsCorporate(false);
    }
  }, [activeSectionNumber, activeStepOutlined, showSnackbar, t, userDataLogin]);

  useEffect(() => {
    if (merchantDetail) {
      setFormData((prev) => ({
        ...prev,
        merchantName: merchantDetail.merchantName,
        urlWeb: merchantDetail.urlDomain,
        npwpNumberCompany: merchantDetail.npwp,
        citizenship: merchantDetail.directorCitizenship,
        ktpNumberDirector: merchantDetail.directorNik,
        passportDirector: merchantDetail.directorPassport,
        address: merchantDetail.address,
        businessTime: merchantDetail.operationYears,
        employeeNumber: merchantDetail.totalEmployees,
        directorName: merchantDetail.directorName,
        guaratorName: merchantDetail.directorPicName,
        idCardGuarantor: merchantDetail.directorPicNik,
        directorAddress: merchantDetail.directorAddress,
        referralName: merchantDetail.merchantMro?.merchantMroUsername || null,
      }));

      if (merchantDetail.directorCitizenship === "Foreign") {
        setIsWNA(true);
      }

      const merchantDocs = merchantDetail.merchantDocuments.filter((data) =>
        data.documentUrl.toLowerCase().includes("paymentflow")
      )[0];

      if (merchantDocs?.documentUrl) {
        getMerchantFile(
          t,
          strings,
          "filePaymentFlow",
          "pdf",
          merchantDocs.documentUrl,
          merchantDocs.fileName,
          setFormData,
          showSnackbar
        );
      }

      if (merchantDetail?.merchantLogo) {
        getMerchantFile(
          t,
          strings,
          "fileMerchantLogo",
          "png",
          merchantDetail.merchantLogo,
          "",
          setFormData,
          showSnackbar
        );
      }
    }
  }, [merchantDetail, showSnackbar, t]);

  const handleChange = async (e, specificName) => {
    const { target } = e;

    if (target) {
      const { name, value, type } = target;
      if (type === "file") {
        const file = e.target.files[0];
        if (file) {
          try {
            const base64File = await convertFileToBase64(file);
            setFormData((prev) => ({
              ...prev,
              [name]: { base64: base64File, fileName: file.name },
            }));
          } catch (error) {}
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        if (name === "citizenship") {
          if (value === "Foreign") {
            setIsWNA(true);
          } else {
            setIsWNA(false);
          }
        }
      }
    } else if (specificName) {
      setFormData((prev) => ({ ...prev, [specificName]: e }));
    }
  };

  useEffect(() => {
    handleDisabledButton();
    calculateProgressValue();
  }, [formData, merchantDetail]);

  const handleDisabledButton = () => {
    const isFormIncomplete =
      formData.loadingSubmit ||
      !formData.merchantName ||
      !isValidName(formData.merchantName) ||
      !formData.urlWeb ||
      !isValidURL(formData.urlWeb) ||
      !formData.npwpNumberCompany ||
      !isValidNPWP(formData.npwpNumberCompany) ||
      !formData.fileMerchantLogo.base64 ||
      !formData.filePaymentFlow.base64;

    const isWNIInfoInvalid =
      !formData.ktpNumberDirector || !isValidIDCard(formData.ktpNumberDirector);

    const isCorporateInfoWNAInvalid =
      !formData.businessTime ||
      !isValidNumber(formData.businessTime) ||
      !formData.employeeNumber ||
      !formData.directorName ||
      !isValidName(formData.directorName) ||
      !formData.passportDirector ||
      !isValidPassport(formData.passportDirector) ||
      !formData.guaratorName ||
      !isValidName(formData.guaratorName) ||
      !formData.idCardGuarantor ||
      !isValidIDCard(formData.idCardGuarantor) ||
      !formData.address ||
      !isValidAddress(formData.address) ||
      !formData.citizenship;

    const isCorporateInfoWNIInvalid =
      !formData.businessTime ||
      !isValidNumber(formData.businessTime) ||
      !formData.employeeNumber ||
      isWNIInfoInvalid ||
      !formData.directorName ||
      !isValidName(formData.directorName) ||
      !formData.directorAddress ||
      !isValidAddress(formData.directorAddress) ||
      !formData.address ||
      !isValidAddress(formData.address) ||
      !formData.citizenship;

    const nonCorporateInvalid =
      !formData.directorName ||
      !isValidName(formData.directorName) ||
      !formData.directorAddress ||
      !isValidAddress(formData.directorAddress);

    if (isCorporate) {
      if (isWNA) {
        return isFormIncomplete || isCorporateInfoWNAInvalid;
      } else if (!isWNA) {
        return isFormIncomplete || isCorporateInfoWNIInvalid;
      }
    } else {
      return isFormIncomplete || isWNIInfoInvalid || nonCorporateInvalid;
    }
  };

  const calculateProgressValue = () => {
    let progressConditions = [
      formData.merchantName,
      formData.businessTime,
      formData.employeeNumber,
      formData.fileMerchantLogo?.base64,
      formData.filePaymentFlow?.base64,
      formData.npwpNumberCompany,
      formData.urlWeb,
    ];

    if (isCorporate) {
      progressConditions.push(
        formData.address,
        formData.citizenship,
        formData.directorName,
        formData.directorAddress,
        formData.referralName
      );

      if (!isWNA) {
        progressConditions.push(formData.ktpNumberDirector);
      } else if (isWNA) {
        progressConditions.push(
          formData.passportDirector,
          formData.guaratorName,
          formData.idCardGuarantor
        );
      }
    } else {
      progressConditions.push(formData.ktpNumberDirector);
    }

    const validProgressCount = progressConditions.filter(Boolean).length;
    const progressNumber =
      (validProgressCount / progressConditions.length) * 20;
    setProgressData((prev) => ({
      ...prev,
      section1: Math.ceil(progressNumber),
    }));
  };

  const submitMerchantInfo = () => {
    const payload = {
      businessType: userDataLogin?.business_type,
      section: activeSectionNumber + 1,
      merchantName: formData?.merchantName,
      urlDomain: formData?.urlWeb,
      npwp: formData?.npwpNumberCompany,
      directorCitizenship: formData?.citizenship || "",
      directorNik: formData.ktpNumberDirector || "",
      directorPassport: formData.passportDirector || "",
      directorName: formData.directorName || "",
      directorAddress: formData.directorAddress || "",
      directorPicName: formData.guaratorName || "",
      directorPicNik: formData.idCardGuarantor || "",
      address: formData?.address || "",
      operationYears: formData.businessTime || "",
      totalEmployees: formData.employeeNumber || "",
      merchantPaymentFlowFileName: formData.filePaymentFlow.fileName || "",
      merchantPaymentFlowFile: formData.filePaymentFlow.base64 || "",
      merchantLogoFileName: formData.fileMerchantLogo.fileName || "",
      merchantLogoFile: formData.fileMerchantLogo.base64 || "",
      registrationCompletion: progressData.section1,
      totalCompletion: 20,
    };

    let mroData = mroList
      .filter((data) => data.id === formData.referralName)
      .map((data) => ({
        mroUsername: data.id,
        mroName: data.label,
        mroMisId: data.keyMRO,
      }))[0];
    payload["merchantMro"] = mroData;

    onSubmitMerchantInfo(
      t,
      strings,
      payload,
      activeSectionNumber,
      setActiveSectionNumber,
      activeStepOutlined,
      setActiveStepOutlined,
      setFormData,
      showSnackbar
    );
  };

  const handleCloseFile = (e, name, label) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById(label).value = "";

    setFormData((prev) => ({
      ...prev,
      [name]: {
        base64: "",
        fileName: "",
      },
    }));
  };

  return !loadingMerchant || !loadingMRO ? (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <div className={classes.divFormStepper}>
        {errorVerificationData?.length > 0 && (
          <div>
            <InfoComponent
              severity="warning"
              message={
                <>
                  <Typography className={classes.textBold600Darker12}>
                    Anda sedang melakukan proses perbaikan data. Adapun data
                    yang harus Anda perbaiki:
                  </Typography>
                  <span>
                    {errorVerificationData?.map((data, index) => (
                      <li key={index} className={classes.textBold600Darker12}>
                        {data.label}
                      </li>
                    ))}
                  </span>
                  <Typography className={classes.textBold600Darker12}>
                    Silahkan untuk melakukan perbaikan pada data diatas.
                  </Typography>
                </>
              }
            />
          </div>
        )}
        {isCorporate ? (
          <>
            {objectMerchantWNA(t, strings)
              .slice(0, 9)

              .map((field, index) => {
                return renderField(
                  t,
                  strings,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar,
                  handleCloseFile,
                  mroList
                );
              })}

            <div className={classes.divLine} />
            <div className={classes.groupWithSubtileSmall}>
              <div>
                <Typography className={classes.textSuperBold18}>
                  {t(strings.textDirectorInformation)}
                </Typography>
                <Typography className={classes.textRegularDarkGray14}>
                  {t(strings.textCompleteDirectorInfo)}
                </Typography>
              </div>
            </div>

            {objectMerchantWNA(t, strings)
              .slice(9, 10)
              .map((field, index) => {
                return renderCitizen(
                  t,
                  strings,
                  classes,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar
                );
              })}

            <div className={classes.divWrapFourColumn}>
              {objectMerchantWNA(t, strings)
                .slice(10, 17)
                .map((field, index) => {
                  return renderDirectorField(
                    t,
                    strings,
                    classes,
                    field,
                    index,
                    formData,
                    handleChange,
                    showSnackbar
                  );
                })}
            </div>

            {objectMerchantWNA(t, strings).map((field, index) => {
              if (field.name === "directorAddress")
                return (
                  <Textfields
                    key={index}
                    isHideLabel={true}
                    placeholder={field.placeholder}
                    label={field.label}
                    type={field.type}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    isValidate={true}
                    isOptional={false}
                    isAny={field.isAny || false}
                  />
                );
            })}
            <Buttons
              loadingSubmit={formData.loadingSubmit}
              label={t(strings.textNext)}
              handleDisabledButton={handleDisabledButton}
              onSubmitData={submitMerchantInfo}
              rightIcon={<RightIcon />}
            />
          </>
        ) : (
          <>
            {objectMerchantWNI(t, strings)
              .slice(0, 6)
              .map((field, index) => {
                return renderField(
                  t,
                  strings,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar,
                  handleCloseFile,
                  mroList
                );
              })}
            <div className={classes.divLine} />
            <div className={classes.groupWithSubtileSmall}>
              <div>
                <Typography className={classes.textSuperBold18}>
                  {t(strings.textBusinessOwnerInformation)}
                </Typography>
                <Typography className={classes.textRegularDarkGray14}>
                  {t(strings.textCompleteBusinessOwnerInfo)}
                </Typography>
              </div>
            </div>

            <div className={classes.divWrapFourColumn}>
              {objectMerchantWNI(t, strings)
                .slice(6, 8)
                .map((field, index) => {
                  return renderBusinessOwnerField(
                    classes,
                    field,
                    index,
                    formData,
                    handleChange
                  );
                })}
            </div>

            {objectMerchantWNI(t, strings)
              .slice(8, 9)
              .map((field, index) => {
                return renderField(
                  t,
                  strings,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar,
                  handleCloseFile,
                  mroList
                );
              })}
            <Buttons
              loadingSubmit={formData.loadingSubmit}
              label={t(strings.textNext)}
              handleDisabledButton={handleDisabledButton}
              onSubmitData={submitMerchantInfo}
              rightIcon={<RightIcon />}
            />
          </>
        )}
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default FormRegistrationInfo;
