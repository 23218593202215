import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../General/Component/HeaderComponent";
import classes from "./activation.module.css";
import strings from "../General/Constant/Locales/Strings/RegistLoginStrings";
import activation from "./Helper/activation";
import decryptData from "../General/Helper/decryptData";
import * as routeEnum from "../General/Constant/routes";
import Buttons from "../General/Component/Buttons";
import DialogFormPopUp from "../General/Component/DialogFormPopUp";

const illustrations = {
  success:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1719308876/35202596_party_popper_1_k4a3sr.png",
  failed:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1719461976/Frame_53348_icvaam.png",
  suspend:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1719461976/Frame_53348_icvaam.png",
};

const ActivationAccount = () => {
  const { token } = useParams();
  const { t } = useTranslation("RegistLogin");
  const navigate = useNavigate();
  const [dataActivation, setDataActivation] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const handleOpenContactUs = () => {
    setOpenContactUs(true);
  };
  const handleCloseContactUs = () => {
    setOpenContactUs(false);
  };

  useEffect(() => {
    if (token) {
      const decodedToken = decryptData(token);
      if (!decodedToken) {
        navigate(routeEnum.ACCESS_DENIED);
      }
    }
  }, [token, navigate]);

  const activateAccount = () => {
    if (token) activation(token, setDataActivation, setLoadingSubmit);
  };

  const getStatus = () => {
    const { response_code, http_response } = dataActivation || {};
    if (response_code === "00" || http_response === 200) return "success";
    if (response_code === "01" || http_response === 400) return "failed";
    return "suspend";
  };

  const status = getStatus();
  const illustrationSrc = illustrations[status];
  const titleText = t(
    status === "success"
      ? strings.textAccountActivationSuccess
      : status === "failed"
      ? strings.textAccountActivationFailed
      : strings.textAccountActivationSuspend
  );
  const descriptionText = t(
    status === "success"
      ? strings.textAccountActivationSuccessDescription
      : status === "failed"
      ? strings.textAccountActivationFailedDescription
      : strings.textAccountActivationSuspendDescription
  );

  const renderActivationAction = () => (
    <div className={classes.divIllustrationStatus}>
      <div className={classes.divTypography}>
        <Typography className={classes.textBold600H1}>
          {t(strings.textActivateYourAccount)}
        </Typography>
        <Typography>{t(strings.textActivateAccountDescription)}</Typography>
      </div>

      <Buttons
        loadingSubmit={loadingSubmit}
        label={t(strings.textActivateAccount)}
        handleDisabledButton={() => {
          return false;
        }}
        onSubmitData={activateAccount}
      />
    </div>
  );

  const renderStatusActivation = () => (
    <div className={classes.divIllustrationStatus}>
      <img
        src={illustrationSrc}
        alt={`${status}-illustration`}
        className={classes.illustrationImage}
      />
      <div className={classes.divTypography}>
        <Typography className={classes.textBold600H1}>{titleText}</Typography>
        <Typography>{descriptionText}</Typography>
      </div>
      <Buttons
        loadingSubmit={false}
        label={t(strings.textContactCS)}
        handleDisabledButton={() => {
          return false;
        }}
        onSubmitData={handleOpenContactUs}
      />
    </div>
  );

  return (
    <div className={classes.divContainer}>
      <HeaderComponent />
      {dataActivation ? renderStatusActivation() : renderActivationAction()}
      <DialogFormPopUp
        open={openContactUs}
        onClose={handleCloseContactUs}
        maxWidth={"xs"}
      />

      <div className={classes.divFooter}>
        <Typography className={classes.textRegularGray500}>
          {t(strings.textCopyright)}
        </Typography>
      </div>
    </div>
  );
};

export default ActivationAccount;
