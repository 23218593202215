import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Registration from "../Registration";
import Login from "../Login";
import * as routesEnum from "../General/Constant/routes";
import { ThemeProvider, createTheme } from "@mui/material";
import ActivationAccount from "../ActivationAccount";
import PrivateRoute from "../General/Helper/PrivateRoute";
import RegistrationMerchant from "../RegistrationMerchant";
import ForgotPassword from "../ForgotResetPassword/forgotPassword";
import ResetPassword from "../ForgotResetPassword/resetPassword";
import ExpiredPage from "../General/Pages/ExpiredPage";
import NotFoundPage from "../General/Pages/NotFoundPage";
import AccessDenied from "../General/Pages/AccessDenied";
import RegistrationForm from "../RegistrationMerchant/RegistrationForm";
import { useDispatch, useSelector } from "react-redux";

import { fetchUserProfile } from "../Store/userSlice";
import { useEffect } from "react";
import PreviewData from "../RegistrationMerchant/PreviewData";

const App = (props) => {
  const theme = createTheme({
    typography: {
      fontFamily: "Inter, sans-serif",
      fontSize: 13,
    },
  });

  const dispatch = useDispatch();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);

  useEffect(() => {
    if (!userDataLogin) {
      dispatch(fetchUserProfile());
    }
  }, [userDataLogin, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider
        router={createBrowserRouter(
          createRoutesFromElements(
            <>
              <Route
                path={routesEnum.REGISTRATION}
                element={<Registration />}
              />

              <Route
                path={`${routesEnum.ACTIVATION_ACCOUNT}/:token`}
                element={<ActivationAccount {...props} />}
              />
              <Route
                path={routesEnum.FORGOT_PASSWORD}
                element={<ForgotPassword {...props} />}
              />
              <Route
                path={`${routesEnum.RESET_PASSWORD}/:token`}
                element={<ResetPassword {...props} />}
              />
              <Route
                path={routesEnum.EXPIRED}
                element={<ExpiredPage {...props} />}
              />
              <Route
                path={routesEnum.ACCESS_DENIED}
                element={<AccessDenied {...props} />}
              />

              <Route path={routesEnum.LOGIN}>
                <Route index element={<Login {...props} />} />
                <Route element={<PrivateRoute />}>
                  <Route
                    path={routesEnum.REGISTRATION_MERCHANT}
                    element={<RegistrationMerchant />}
                  />

                  <Route
                    path={routesEnum.REGISTRATION_FORM}
                    element={<RegistrationForm />}
                  />

                  <Route
                    path={routesEnum.PREVIEW_DATA}
                    element={<PreviewData />}
                  />

                  <Route path="*" element={<NotFoundPage />} />
                </Route>
              </Route>
            </>
          )
        )}
      />
    </ThemeProvider>
  );
};

export default App;
