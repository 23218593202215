const objectPaymentService = (t, strings) => [
  {
    name: "serviceCategory",
    placeholder: t(strings.textPlaceholderPaymentType),
    label: t(strings.textSelectPaymentService),
    type: "text",
    isValidate: true,
  },
  {
    name: "serviceDetails",
    placeholder: t(strings.textPlaceholderPaymentType),
    label: t(strings.textSelectPaymentService),
    type: "text",
    isValidate: true,
  },
  {
    name: "channels",
    placeholder: t(strings.textPlaceholderPaymentType),
    label: t(strings.textSelectPaymentService),
    type: "text",
    isValidate: true,
  },
  {
    name: "disbursementServiceDetails",
    placeholder: t(strings.textPlaceholderPaymentType),
    label: t(strings.textSelectPaymentService),
    type: "text",
    isValidate: true,
  },
  {
    name: "notes",
    placeholder: t(strings.textPlaceholderNotes),
    label: t(strings.textNotes),
    type: "text",
    isValidate: false,
    isOptional: true,
    isHideLabel: true,
  },
];

export default objectPaymentService;
