const listPaymentCategory = (t, strings) => [
  {
    id: "Credit Card",
    label: t(strings.textCreditCard),
    notes: t(strings.textMustHaveBCAVirtualAccount),
  },
  { id: "Virtual Account", label: t(strings.textVirtualCard), notes: "" },
  { id: "Wallet", label: t(strings.textWallet), notes: "" },
  { id: "OTC", label: t(strings.textOTC), notes: "" },
];

export default listPaymentCategory;
