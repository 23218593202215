import strings from "../Constant/Locales/Strings/GeneralStrings";
import { useTranslation } from "react-i18next";
import PageWithIllustration from "../Component/PageWithIllustration";

const AccessDenied = () => {
  const { t } = useTranslation("General");
  const illustration =
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838243/405_1_xp7uht.png";

  return (
    <PageWithIllustration
      illustration={illustration}
      textTitle={t(strings.textAccessDenied)}
      textSubTitle={t(strings.textDescAccessDenied)}
      textErrorCode=""
      buttonAction={null}
    />
  );
};

export default AccessDenied;
