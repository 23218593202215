import { Typography } from "@mui/material";
import classes from "../Style/general.module.css";

const successImage =
  "https://res.cloudinary.com/pastisah-id/image/upload/v1723798662/digital-product-logo/web-illustration/SuccessIllustration_dxofod.png";

const waitingImage =
  "https://res.cloudinary.com/pastisah-id/image/upload/v1729227282/Clocks_spz5j7.png";

const failedImage =
  "https://res.cloudinary.com/pastisah-id/image/upload/v1729234099/error_sbr7cb.png";

export const CardInformation = ({ header, text, status }) => {
  let statusImage = "";
  if (status === "success") {
    statusImage = successImage;
  } else if (status === "error" || status === "failed") {
    statusImage = failedImage;
  } else if (status === "waiting") {
    statusImage = waitingImage;
  }

  return (
    <div className={classes.boxSuccessState}>
      <img src={statusImage} className={classes.imageSuccess} alt="" />
      <div>
        <Typography className={classes.textBold600H1}>{header}</Typography>
        <Typography className={classes.textRegularDarkGray}>{text}</Typography>
      </div>
    </div>
  );
};
