import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Typography,
} from "@mui/material";
import classes from "../Style/general.module.css";

const RadioButtons = ({
  label,
  name,
  value,
  onChange,
  options,
  row = true,
  ...props
}) => {
  return (
    <>
      <div className={classes.divFormField}>
        <Typography className={classes.textBold600BlackH4}>{label}</Typography>
        <FormControl component="fieldset" {...props}>
          <RadioGroup
            row={row}
            name={name || ""}
            value={value || ""}
            onChange={onChange}
          >
            {options?.map((option, index) => (
              <FormControlLabel
                key={option.value || index}
                value={option.value || ""}
                control={
                  <Radio
                    sx={{
                      color: "#cfdfec",
                      "&.Mui-checked": {
                        color: "#CAA406",
                      },
                    }}
                  />
                }
                label={option.label || ""}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

export default RadioButtons;
