import classes from "../RegistMerchant.module.css";
import AccordionPreview from "../Component/AccordionPreview";

const PreviewData = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
}) => {
  return (
    <>
      <div className={classes.divFormStepper}>
        <AccordionPreview
          activeSectionNumber={activeSectionNumber}
          setActiveSectionNumber={setActiveSectionNumber}
          activeStepOutlined={activeStepOutlined}
          setActiveStepOutlined={setActiveStepOutlined}
          progressData={progressData}
        />
      </div>
    </>
  );
};

export default PreviewData;
