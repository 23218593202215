import { Button, Typography } from "@mui/material";
import classes from "../Style/general.module.css";
import { useEffect, useState } from "react";
import i18n from "../../i18n";
import ExpandMoreIcon from "../Icon/ExpandMoreIcon";
import PopOverComponent from "./PopOverComponent";
import ExpandLessIcon from "../Icon/ExpandLessIcon";

const SelectLanguage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [selectedLanguage, setSelectedLanguage] = useState("Indonesia");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    i18n.changeLanguage("id");
    localStorage.setItem("lang", "id");
  }, []);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    if (language === "English") {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
    } else if (language === "Indonesia") {
      i18n.changeLanguage("id");
      localStorage.setItem("lang", "id");
    }
    handleClose();
  };

  const languages = [
    {
      id: "Indonesia",
      label: "Indonesia",
      icon: null,
      handleItemButton: handleLanguageChange,
    },
    {
      id: "English",
      label: "English",
      icon: null,
      handleItemButton: handleLanguageChange,
    },
    // Add more languages as needed
  ];

  const showLanguageButton = () => {
    return (
      <div className={classes.divSelectLanguage}>
        <Button
          className={classes.languageButton}
          aria-describedby={id}
          onClick={handleClick}
          disableRipple
        >
          <Typography className={classes.textBold600}>
            {selectedLanguage}
          </Typography>

          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>

        <PopOverComponent
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          listItems={languages}
          selectedData={selectedLanguage}
          handleItemButton={handleLanguageChange}
        />
      </div>
    );
  };

  return showLanguageButton();
};

export default SelectLanguage;
