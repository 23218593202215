import strings from "../Constant/Locales/Strings/GeneralStrings";
import { useTranslation } from "react-i18next";
import PageWithIllustration from "../Component/PageWithIllustration";
import LeftArrowIcon from "../Icon/LeftArrowIcon";
import * as routeEnum from "../../General/Constant/routes";
import { useNavigate } from "react-router-dom";
import Buttons from "../Component/Buttons";

const illustration =
  "https://res.cloudinary.com/pastisah-id/image/upload/v1724211165/not_found_ctdx99.png";

const NotFoundPage = () => {
  const { t } = useTranslation("General");
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate(routeEnum.LOGIN);
  };

  return (
    <PageWithIllustration
      illustration={illustration}
      textTitle={t(strings.textPageNotFoundTitle)}
      textSubTitle={t(strings.textPageNotFoundSubtitle)}
      textErrorCode={t(strings.textPageNotFoundCode)}
      buttonAction={
        <div style={{ margin: "32px 0 " }}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textBack)}
            handleDisabledButton={() => {
              return false;
            }}
            onSubmitData={goToLogin}
            leftIcon={<LeftArrowIcon />}
          />
        </div>
      }
    />
  );
};

export default NotFoundPage;
