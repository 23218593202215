const WarningIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 18}
    height={heightIcon || 18}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M16.65 13.2251L10.5012 2.54677C10.3475 2.28515 10.1282 2.06823 9.86487 1.91751C9.60156 1.76679 9.30342 1.6875 9.00002 1.6875C8.69662 1.6875 8.39849 1.76679 8.13517 1.91751C7.87186 2.06823 7.6525 2.28515 7.49885 2.54677L1.35002 13.2251C1.20218 13.4782 1.12427 13.766 1.12427 14.059C1.12427 14.3521 1.20218 14.6399 1.35002 14.8929C1.50171 15.1561 1.72069 15.3742 1.98449 15.5249C2.2483 15.6755 2.54743 15.7532 2.85119 15.75H15.1489C15.4524 15.753 15.7512 15.6751 16.0148 15.5245C16.2783 15.3739 16.497 15.1559 16.6486 14.8929C16.7967 14.64 16.8748 14.3523 16.8751 14.0592C16.8753 13.7662 16.7977 13.4783 16.65 13.2251ZM8.43752 7.31255C8.43752 7.16336 8.49679 7.02029 8.60228 6.9148C8.70776 6.80931 8.85084 6.75005 9.00002 6.75005C9.14921 6.75005 9.29228 6.80931 9.39777 6.9148C9.50326 7.02029 9.56252 7.16336 9.56252 7.31255V10.125C9.56252 10.2742 9.50326 10.4173 9.39777 10.5228C9.29228 10.6283 9.14921 10.6875 9.00002 10.6875C8.85084 10.6875 8.70776 10.6283 8.60228 10.5228C8.49679 10.4173 8.43752 10.2742 8.43752 10.125V7.31255ZM9.00002 13.5C8.83314 13.5 8.67001 13.4506 8.53126 13.3578C8.39251 13.2651 8.28436 13.1334 8.2205 12.9792C8.15664 12.825 8.13993 12.6554 8.17248 12.4917C8.20504 12.328 8.2854 12.1777 8.4034 12.0597C8.5214 11.9417 8.67174 11.8613 8.83541 11.8288C8.99909 11.7962 9.16874 11.8129 9.32291 11.8768C9.47709 11.9406 9.60886 12.0488 9.70158 12.1875C9.79429 12.3263 9.84377 12.4894 9.84377 12.6563C9.84377 12.8801 9.75488 13.0947 9.59664 13.2529C9.43841 13.4112 9.2238 13.5 9.00002 13.5Z"
      fill="#CAA406"
    />
  </svg>
);

export default WarningIcon;
