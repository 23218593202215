export const documentConstant = (isCorporate, isWNA) => [
  {
    code: "directorId",
    name: isCorporate
      ? isWNA
        ? "filePassportDirector"
        : "fileDirectorKTP"
      : "fileKTPPerson",
  },
  {
    code: "tdpse",
    name: "fileTDPSE",
  },
  {
    code: "amendmentDeed",
    name: "fileDeedEstablishment",
  },
  {
    code: "ministryPermit",
    name: "fileSKKemenkumham",
  },
  {
    code: "nib",
    name: "fileNIB",
  },
  {
    code: "companyPermit",
    name: "fileInstitutionPermit",
  },
  {
    code: "accountNumber",
    name: "fileBankNumber",
  },
  {
    code: "npwp",
    name: "fileNPWP",
  },
  {
    code: "picId",
    name: "fileKTPPIC",
  },
];
