const documentList = (t, strings) => [
  { id: "E-KTP", label: "E-KTP", corporate: false },
  { id: "E-KTP Direktur", label: t(strings.textDirectorEKTP), corporate: true },
  {
    id: "Nomor Rekening Company",
    label: t(strings.textAccountNumber),
    corporate: true,
  },
  {
    id: "Nomor Rekening Pribadi",
    label: t(strings.textAccountNumber),
    corporate: false,
  },
  { id: "NPWP Perusahaan", label: t(strings.textCompanyNPWP), corporate: true },
  { id: "NPWP Pribadi", label: t(strings.textPersonalNPWP), corporate: false },
  { id: "TD PSE", label: t(strings.textTDPSE), corporate: true },
  {
    id: "Akta Pendirian & Perubahan",
    label: t(strings.textDeedEstablishmentAndAmendment),
    corporate: true,
  },
  {
    id: "SK Kemenkumham",
    label: t(strings.textMinistryLawAndHumanRightsDecree),
    corporate: true,
  },
  { id: "NIB", label: t(strings.textNIB), corporate: true },
  {
    id: "Izin Lembaga",
    label: t(strings.textInstitutionLicence),
    corporate: true,
  },
];

export default documentList;
