// Payment icons
const paymentIcons = {
  Visa: "https://res.cloudinary.com/pastisah-id/image/upload/v1727837819/digital-product-logo/Payment%20Logo/Visa-Mastercard_oyhbk4.jpg",
  VACIMB:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_22_f6xciy.png",
  VAPermata:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838334/digital-product-logo/Payment%20Logo/Bank_Permata_-koleksilogo.com_kx7jbg.png",
  VABNI:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838334/digital-product-logo/Payment%20Logo/BNI_logo.svg_eq8dph.png",
  VABCA:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_9_osicbm.png",
  VABRI:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838355/digital-product-logo/Payment%20Logo/image_31_ygvqkp.png",
  VAMandiri:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_10_gjudvg.png",
  VANeo:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_19_mbsllj.png",
  OVO: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_13_z5gkr7.png",
  ShopeePay:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_14-1_piakqm.png",
  LinkAja:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_23_id2f8l.png",
  DANA: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_24_j0ssji.png",
  Alipay:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_26_oopgia.png",
  Virgo:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_28_eptha3.png",
  WeChat:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838355/digital-product-logo/Payment%20Logo/image_25_pomjau.png",
  QRIS: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_20_emekfg.png",
  NOBU: "https://res.cloudinary.com/pastisah-id/image/upload/v1727838354/digital-product-logo/Payment%20Logo/image_27_bewcn6.png",
  Finpay:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_16_teewse.png",
  Alfamart:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_29_md9i5n.png",
  Pegadaian:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1727838353/digital-product-logo/Payment%20Logo/image_30_tksaha.png",
};

// Payment channel list
const listPaymentChannel = [
  {
    id: "Credit Card",
    name: "Visa Master Card",
    checked: false,
    label: "Visa Master Card",
    icon: paymentIcons.Visa,
  },
  {
    id: "Virtual Account",
    name: "CIMB NIAGA",
    checked: false,
    label: "CIMB NIAGA",
    icon: paymentIcons.VACIMB,
  },
  {
    id: "Virtual Account",
    name: "Permata Bank",
    checked: false,
    label: "Permata Bank",
    icon: paymentIcons.VAPermata,
  },
  {
    id: "Virtual Account",
    name: "Bank BNI",
    checked: false,
    label: "Bank BNI",
    icon: paymentIcons.VABNI,
  },
  {
    id: "Virtual Account",
    name: "Bank BCA",
    checked: false,
    label: "Bank BCA",
    icon: paymentIcons.VABCA,
  },
  {
    id: "Virtual Account",
    name: "Bank BRI",
    checked: false,
    label: "Bank BRI",
    icon: paymentIcons.VABRI,
  },
  {
    id: "Virtual Account",
    name: "Bank Mandiri",
    checked: false,
    label: "Bank Mandiri",
    icon: paymentIcons.VAMandiri,
  },
  {
    id: "Virtual Account",
    name: "Bank Neo Commerce",
    checked: false,
    label: "Bank Neo Commerce",
    icon: paymentIcons.VANeo,
  },
  {
    id: "Wallet",
    name: "OVO",
    checked: false,
    label: "OVO",
    icon: paymentIcons.OVO,
  },
  {
    id: "Wallet",
    name: "Shopee Pay",
    checked: false,
    label: "Shopee Pay",
    icon: paymentIcons.ShopeePay,
  },
  {
    id: "Wallet",
    name: "Link Aja!",
    checked: false,
    label: "Link Aja!",
    icon: paymentIcons.LinkAja,
  },
  {
    id: "Wallet",
    name: "Dana",
    checked: false,
    label: "Dana",
    icon: paymentIcons.DANA,
  },
  {
    id: "Wallet",
    name: "Alipay",
    checked: false,
    label: "Alipay",
    icon: paymentIcons.Alipay,
  },
  {
    id: "Wallet",
    name: "Virgo",
    checked: false,
    label: "Virgo",
    icon: paymentIcons.Virgo,
  },
  {
    id: "Wallet",
    name: "Wechat",
    checked: false,
    label: "Wechat",
    icon: paymentIcons.WeChat,
  },
  {
    id: "Wallet",
    name: "QRIS",
    checked: false,
    label: "QRIS",
    icon: paymentIcons.QRIS,
  },
  {
    id: "Wallet",
    name: "QRIS Nobu",
    checked: false,
    label: "QRIS Nobu",
    icon: paymentIcons.NOBU,
  },
  {
    id: "Wallet",
    name: "QRIS SHOPEE PAY",
    checked: false,
    label: "QRIS Shopee Pay",
    icon: paymentIcons.ShopeePay,
  },
  {
    id: "Over The Counter (OTC)",
    name: "Finpay",
    checked: false,
    label: "Finpay",
    icon: paymentIcons.Finpay,
  },
  {
    id: "Over The Counter (OTC)",
    name: "Alfamart by Finpay",
    checked: false,
    label: "Alfamart by Finpay",
    icon: paymentIcons.Alfamart,
  },
  {
    id: "Over The Counter (OTC)",
    label: "Pegadaian by Finpay",
    name: "Pegadaian by Finpay",
    checked: false,
    icon: paymentIcons.Pegadaian,
  },
];

export default listPaymentChannel;
